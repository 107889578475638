export const IS_AUTHENTICATED = 'isAuthenticated'
export const IS_PROFILE_COMPLETE = 'isProfileComplete'
export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_TOKEN_NOT_FOUND_OR_INVALID = 'AUTH_TOKEN_NOT_FOUND_OR_INVALID'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const LOGOUT_SILENTLY = 'LOGOUT_SILENTLY'
export const PROFILE_REQUEST = 'PROFILE_REQUEST'
export const PROFILE_RES_CONSENT_STATUS = 'PROFILE_RES_CONSENT_STATUS'
export const PROFILE_STATUS = 'PROFILE_STATUS'
export const AUTH_IDLE_TIMEOUT = 'AUTH_IDLE_TIMEOUT'
export const HAS_PASSWORD_TOKEN = 'HAS_PASSWORD_TOKEN'
export const ORG_BY_ACCESS_CODE = 'ORG_BY_ACCESS_CODE'
export const RESET_ORG_BY_ACCESS_CODE = 'RESET_ORG_BY_ACCESS_CODE'
export const SET_PROFILE_REQUEST_PROMISE = 'SET_PROFILE_REQUEST_PROMISE'
