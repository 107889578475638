const FLOW_NAME_KIT_ACTIVATION = 'Kit activation'
const FLOW_NAME_SIGNED_OUT_ORDERING = 'Signed out ordering'
const FLOW_NAME_SIGNED_IN_ORDERING = 'Signed in ordering'
const FLOW_NAME_SELECT_TEST = 'Select a test'

export const getFlowName = (to) => {
  if (to.name === 'SelectTest') return FLOW_NAME_SELECT_TEST

  if (
    to.path.startsWith(`/${to.params.lang}/patient`) ||
    to.path.startsWith(`/${to.params.lang}/direct`)
  )
    return FLOW_NAME_SIGNED_OUT_ORDERING
  if (to.path.startsWith(`/${to.params.lang}/prescreen`)) return FLOW_NAME_SIGNED_IN_ORDERING
  if (to.path.startsWith(`/${to.params.lang}/kit-activation`)) return FLOW_NAME_KIT_ACTIVATION

  return ''
}
