<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.636.45a5.242 5.242 0 015.232 5.232 5.242 5.242 0 01-5.232 5.232 5.242 5.242 0 01-5.231-5.232A5.242 5.242 0 019.636.45zm0 10.364a8.64 8.64 0 018.637 8.736l-1.777-.05a6.847 6.847 0 00-6.86-6.86c-3.798 0-6.859 3.062-6.909 6.91L.95 19.5c0-4.784 3.902-8.686 8.686-8.686zm0-8.537a3.394 3.394 0 00-3.404 3.405 3.394 3.394 0 003.404 3.404 3.394 3.394 0 003.405-3.404 3.394 3.394 0 00-3.405-3.405z"
    fill="currentColor"
  />
</template>

<script>
export default {
  name: 'IxIconProfile'
}
</script>
