import { HAS_CONNECTION_ERROR } from '@/store/notifications.js'

import PopupV3 from '@/components/ui-components/Popup/PopupV3.vue'

export default {
  name: 'ErrorPopup',
  components: {
    PopupV3
  },

  methods: {
    closePopup() {
      this.$store.dispatch(HAS_CONNECTION_ERROR, false)
      location.reload()
      return false
    }
  }
}
