import { getCurrentInstance } from 'vue'

export default function useTranslation() {
  const instance = getCurrentInstance()
  const vm = instance?.proxy

  if (!vm) {
    throw new Error(
      'useTranslation only works during setup or Lifecycle Hooks.' +
        'Please call useTranslation() on setup and use the instance afterward.' +
        'Ref: https://v3.vuejs.org/api/composition-api.html#getcurrentinstance'
    )
  }

  return vm.$t.bind(vm)
}
