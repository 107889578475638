import IXLayerAPI from '@/classes/IXLayerAPI.js'
import common from '@/classes/common.js'

export const FETCH_SURVEY_LIST = 'FETCH_SURVEY_LIST'
export const SET_SURVEY_LIST = 'SET_SURVEY_LIST'
export const RESET_SURVEY_LIST = 'RESET_SURVEY_LIST'

const initialSurveyListState = () => ({ surveyList: null })

const state = {
  ...initialSurveyListState()
}

const getters = {
  surveyList: (state) => state.surveyList
}

const actions = {
  [FETCH_SURVEY_LIST]: ({ commit }, params = {}) => {
    return new Promise((resolve, reject) => {
      IXLayerAPI.getSurveys(params)
        .then((resp) => {
          commit(SET_SURVEY_LIST, resp)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

const mutations = {
  [SET_SURVEY_LIST]: (state, resp) => {
    state.surveyList = resp.data.results
  },
  [RESET_SURVEY_LIST](state) {
    common.resetState(initialSurveyListState, state)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
