export const installLinkedInInsightTag = (linkedInPartnerId, isCookieConsentEnabled = false) => {
  const isSamePartnerIdInstalled = window._linkedin_partner_id === linkedInPartnerId
  if (isSamePartnerIdInstalled) return

  const existingTag = document.getElementById('linkedin-insight-tag')
  if (existingTag !== null) existingTag.remove()

  if (!linkedInPartnerId) return

  window._linkedin_partner_id = linkedInPartnerId
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []
  window._linkedin_data_partner_ids.push(window._linkedin_partner_id)

  window.lintrk = function (a, b) {
    window.lintrk.q.push([a, b])
  }
  window.lintrk.q = []

  const linkedInInsightTagScript = document.createElement('script')
  linkedInInsightTagScript.id = 'linkedin-insight-tag'
  linkedInInsightTagScript.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js'
  linkedInInsightTagScript.async = true
  if (isCookieConsentEnabled) {
    linkedInInsightTagScript.type = 'text/plain'
    linkedInInsightTagScript.dataset.categories = 'advertising'
  }

  document.head.append(linkedInInsightTagScript)
}
