import { pick } from 'lodash-es'
import { hasFlowDataType } from '@/utils/productUtils.js'

export const productStatusFields = {
  vaccination: ['vaccination'],
  physician_order_at_home: [''],
  has_at_home_test: ['questionnaire_response']
}

export const profileFields = {
  product_status: ['product_status'],
  enableHealthSurvey: ['research_consent_date', 'research_consent_status'],
  insurance: ['insurance_info'],
  showPassport: ['passport_issued_country', 'passport_number']
}

const _isSupported = (getters, key) =>
  getters.featureFlags[key] ||
  (getters.organization ? hasFlowDataType(key, getters.productList, getters.organization) : null)

const getOmittedFieldsStr = (fields, featureKeys) => {
  const filteredFields = pick(fields, featureKeys)
  return Object.values(filteredFields).join()
}

export const getOmittedFields = (fields, getters) => {
  const ignoredKeys = Object.keys(fields).filter((key) => !_isSupported(getters, key))

  return getOmittedFieldsStr(fields, ignoredKeys)
}

export const allOmittedProductStatusFields = getOmittedFieldsStr(
  productStatusFields,
  Object.keys(productStatusFields)
)
