import axios from 'axios'
import cookies from 'browser-cookies'
import { defaultLanguage, supportedLanguages } from '@/config/translations.js'
import { i18n } from '@/plugins/i18n.js'
import { CONFIG } from '@/constants/config.js'
import { getConfig } from '@/use/useConfig.js'
import { getFallbackPath } from '@/utils/routeUtils.js'

const Trans = {
  get defaultLanguage() {
    return defaultLanguage
  },
  get supportedLanguages() {
    // Getting the Frontend config languages
    const languages = getConfig(CONFIG.LANGUAGES)

    // Filtering out unset languages based on the Frontend config languages
    const availableLanguages = supportedLanguages
      .filter((item) => languages.includes(item.abbreviation))
      .map((lang) => lang.abbreviation)

    // Checking to see if the default language is coming with Frontend config languages. If not, we add it here
    if (!availableLanguages.includes(defaultLanguage)) {
      availableLanguages.unshift(defaultLanguage)
    }
    return availableLanguages
  },
  get supportedLanguagesData() {
    // Filtering the supportedlanguages array by the availableLanguages
    return supportedLanguages.filter((item) => Trans.supportedLanguages.includes(item.abbreviation))
  },
  get currentLanguage() {
    return i18n.locale
  },
  set currentLanguage(lang) {
    i18n.locale = lang
  },
  /**
   * Gets the first supported language that matches the user's
   * @return {String}
   */
  getUserSupportedLang() {
    const userPreferredLang = Trans.getUserLang()

    // Check if user preferred browser lang is supported
    if (Trans.isLangSupported(userPreferredLang.lang)) {
      return userPreferredLang.lang
    }
    // Check if user preferred lang without the ISO is supported
    if (Trans.isLangSupported(userPreferredLang.langNoISO)) {
      return userPreferredLang.langNoISO
    }
    return Trans.defaultLanguage
  },
  /**
   * Returns the users preferred language
   */
  getUserLang() {
    const lang =
      cookies.get('language') ||
      window.navigator.language ||
      window.navigator.userLanguage ||
      Trans.defaultLanguage
    return {
      lang: lang,
      langNoISO: lang.split('-')[0]
    }
  },
  /**
   * Sets the language to various services (axios, the html tag etc)
   * @param {String} lang
   * @return {String} lang
   */
  setI18nLanguageInServices(lang) {
    Trans.currentLanguage = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
  },
  /**
   * Loads new translation messages and changes the language when finished
   * @param lang
   * @return {Promise<any>}
   */
  changeLanguage(lang) {
    if (!Trans.isLangSupported(lang)) return Promise.reject(new Error('Language not supported'))
    if (i18n.locale === lang) return Promise.resolve(lang) // has been loaded prior
    return Trans.loadLanguageFile(lang).then((msgs) => {
      i18n.setLocaleMessage(lang, msgs.default || msgs)
      // set language in cookie
      cookies.set('language', lang)
      return Trans.setI18nLanguageInServices(lang)
    })
  },
  /**
   * Async loads a translation file
   * @param lang
   * @return {Promise<*>|*}
   */
  loadLanguageFile(lang) {
    return import(`@/locales/${lang}.json`)
  },
  /**
   * Checks if a lang is supported
   * @param {String} lang
   * @return {boolean}
   */
  isLangSupported(lang) {
    return Trans.supportedLanguages.includes(lang)
  },
  /**
   * Checks if the route's param is supported, if not, redirects to the first supported one.
   * @param {Route} to
   * @param {Route} from
   * @param {Function} next
   * @return {*}
   */
  routeMiddleware(to, from, next) {
    const lang = to.params.lang

    // If user tries to use an unsupported language in the url, we redirect them to fallback page with user's supported lang
    if (!Trans.isLangSupported(lang))
      return next(`${Trans.getUserSupportedLang()}/${getFallbackPath()}`)
    return Trans.changeLanguage(lang).then(() => {
      if (to.path === `/${lang}` || to.path === `/${lang}/`) {
        return next(`/${lang}/${getFallbackPath()}`)
      }

      return next()
    })
  },
  /**
   * Returns a new route object that has the current language already defined
   * To be used on pages and components, outside of the main \ route, like on Headers and Footers.
   * @example <router-link :to="$i18nRoute({ name: 'someRoute'})">Click Me </router-link>
   * @param {Object} to - route object to construct
   */
  i18nRoute(to) {
    return {
      ...to,
      params: { lang: this.currentLanguage, ...to.params }
    }
    // return { path:  this.currentLanguage + '/' + to.path }
  }
}

export { Trans }
