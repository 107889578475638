import { CONSENT_TYPES } from '@/constants/consent.js'

export const hasAcceptedMandatoryConsents = (profile) => {
  const acceptedConsentTypes = profile?.consent?.map((consent) => consent.consent_type) || []

  return (
    acceptedConsentTypes.includes(CONSENT_TYPES.TERMS_OF_USE) &&
    acceptedConsentTypes.includes(CONSENT_TYPES.PRIVACY_POLICY)
  )
}

export const hasSignedConsent = (profile, consentType) => {
  return profile?.consent?.some(
    (consent) => consent.consent_type === consentType && consent.consent_signed_date !== null
  )
}
