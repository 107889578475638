import { RESULT_DATA_SOURCE } from '@/constants/resultDataSource.js'
import { get } from 'lodash-es'
import { PRODUCT_LINK_ANCHORS } from '@/constants/product.js'

const IxIconPaperPlane = () => import('@/components/icons/IxIconPaperPlane.vue')
const IxIconEmail = () => import('@/components/icons/IxIconEmail.vue')
const IxIconPrint = () => import('@/components/icons/IxIconPrint.vue')
const IxIconClipboard = () => import('@/components/icons/IxIconClipboard.vue')
const IxIconDownload = () => import('@/components/icons/IxIconDownload.vue')
const IxIconSurvey = () => import('@/components/icons/IxIconSurvey.vue')
const IxIconPhone = () => import('@/components/icons/IxIconPhone.vue')
const IxIconStethoscope = () => import('@/components/icons/IxIconStethoscope.vue')
const IxIconArrowUpRightFromSquare = () =>
  import('@/components/icons/IxIconArrowUpRightFromSquare.vue')

export const isSelfReportedProduct = (product = {}) =>
  product?.resultDataSource === RESULT_DATA_SOURCE.SELF_REPORTED

export const getAcuitySchedulingLink = (
  params = {},
  baseUrl = 'https://app.squarespacescheduling.com'
) => {
  if (!params.owner) {
    return ''
  }

  const urlParams = new URLSearchParams()
  urlParams.set('owner', params.owner.toString())
  urlParams.set('firstName', params?.firstName)
  urlParams.set('lastName', params?.lastName)
  urlParams.set('email', params?.email)
  urlParams.set('phone', params?.phone)

  if (params.calendar_id) {
    urlParams.set('calendarID', params.calendar_id.toString())
  }

  if (params.appointment_type) {
    urlParams.set('appointmentType', params.appointment_type.toString())
  }

  return `${baseUrl}/schedule.php?${urlParams.toString()}`
}

export const goToPurchaseProduct = (product, productStatusId, orderId, orgId, router) => {
  const productId = product.id
  router
    .push({
      name: 'OrderTestCheckout',
      params: { orgId, productId, orderId, productStatusId, product }
    })
    .catch(() => {})
}

/**
 * @typedef {{
 *  collection_type: string;
 *  description: string;
 *  flow_data: Object;
 *  id: number;
 *  image: string;
 *  uiKitMask: string;
 *  name: string;
 *  price: number;
 *  questionnaireName: string;
 *  sku: string;
 * }} Product
 *
 * @typedef {{
 *  approval_method: string;
 *  automatic_fulfillment: boolean;
 *  box_image: string | null;
 *  bulk_quantity: number;
 *  collection_type: string;
 *  content: string | null;
 *  description: string;
 *  external_purchase_url: string | null;
 *  flow_data: Object;
 *  iap_identifier: string;
 *  id: number;
 *  is_point_of_care: boolean;
 *  kit_id_check_regex: string;
 *  name: string;
 *  price: number;
 *  product_consent_needed: boolean;
 *  product_image: string;
 *  product_type: string;
 *  questionnaire_name: string;
 *  results_delivery_method: string;
 *  sku: string;
 *  slug: string;
 *  standard_vaccine_dose: null|string;
 *  test_type: null|string;
 *  ui_kit_mask: string;
 *  vis_date: null|string;
 * }} Product
 *
 * @param {FullProduct} product
 * @param {Organization} organization
 * @return {Product}
 */
export function getProductDetails(product, organization) {
  if (!product) {
    return {}
  }

  let flowData = null

  if (product.flow_data?.default) {
    const orgSlug = organization?.slug
    flowData = get(product, `flow_data[${orgSlug}]`, product.flow_data.default)
  } else {
    flowData = product.flow_data
  }

  return {
    collectionType: product.collection_type,
    collectionMethod: product.collection_method,
    description: product.description,
    externalSurvey: product.external_survey || [],
    excludedStates: product.excluded_states || [],
    flowData,
    iapIdentifier: product.iap_identifier,
    id: product.id,
    image: product.product_image,
    boxImage: product.box_image,
    isPointOfCare: product.is_point_of_care,
    resultDataSource: product?.result_data_source,
    labInfo: product.lab_info,
    biomarkerId: product.biomarkers?.[0]?.id,
    name: product.name,
    price: product.price,
    questionnaireName: product.questionnaire_name,
    sku: product.sku,
    slug: product.slug,
    type: product.product_type.toLowerCase(),
    uiKitMask: product.ui_kit_mask,
    category: product?.category?.name,
    links: product?.links,
    hasInstructions: product?.has_instructions,
    resultSummaryDescription: product?.result_summary_description,
    resultViewer: product?.result_viewer
  }
}

/**
 * Method to get product object from given
 * productList, productId,  & organization.
 *
 * @typedef {{
 *  address: string;
 *  city: string;
 *  id: number;
 *  logo: string;
 *  name: string;
 *  phone: string;
 *  products: Array<Object>;
 *  slug: string;
 *  state: string;
 *  zip: string;
 * }} Organization
 *
 * @param {Array<Product>} productList
 * @param {Number|String} productId
 * @param {Organization} organization
 * @returns {Product}
 */
export function getProduct(productList, productId, organization) {
  productId = parseInt(productId, 10)
  const product = productList.find((product) => product?.id === productId)
  return product ? getProductDetails(product, organization) : null
}

export const hasFlowDataType = (type, productList, organization) => {
  const orgProducts = productList.filter((product) =>
    organization.products.find((val) => val.id === product.id)
  )

  return (
    orgProducts.filter((product) => getProductDetails(product, organization).flowData?.[type])
      .length > 0
  )
}

export const allProductsHasFlowDataType = (type, productList, organization, productType = null) => {
  let filteredOrgProductsByType = null
  if (productType) {
    filteredOrgProductsByType = filterOrgProductsByType(productType, organization.products)
  }
  const orgProducts = filteredOrgProductsByType || organization.products
  const filteredOrgProducts = productList.filter((product) =>
    orgProducts.find((val) => val.id === product.id)
  )
  return filteredOrgProducts.every(
    (product) => getProductDetails(product, organization).flowData[type]
  )
}

export const filterOrgProductsByType = (productType, orgProducts) => {
  const allCapsProductType = productType.toUpperCase()
  return orgProducts.filter((product) => product.product_type === allCapsProductType)
}

export const filterNoAtHomeOrderableProducts = (products, org) => {
  return (products || []).filter((item) => {
    const product = getProductDetails(item, org)
    return !product.flowData?.at_home_no_order
  })
}

export const getProductLinkIcon = (productLink) => {
  const anchor = productLink?.anchor?.replace('#', '')

  if (anchor === PRODUCT_LINK_ANCHORS.DOCTOR_DISCUSSION_GUIDE) {
    return IxIconClipboard
  }

  if (
    anchor === PRODUCT_LINK_ANCHORS.FIND_A_DOCTOR ||
    anchor === PRODUCT_LINK_ANCHORS.FIND_A_SPECIALIST
  ) {
    return IxIconStethoscope
  }

  if (anchor === PRODUCT_LINK_ANCHORS.EFAX_RESULT) {
    return IxIconPaperPlane
  }

  if (anchor === PRODUCT_LINK_ANCHORS.EMAIL_RESULT) {
    return IxIconEmail
  }

  switch (productLink.icon) {
    case 'print':
      return IxIconPrint
    case 'download':
      return IxIconDownload
    case 'survey':
      return IxIconSurvey
    case 'phone':
      return IxIconPhone
    case 'stethoscope':
      return IxIconStethoscope
    case 'external':
      return IxIconArrowUpRightFromSquare
    default:
      return null
  }
}

export const isFeatureEnabledForAnchor = (anchor, featureFlags) => {
  if (!anchor) return true

  let name = anchor.replace('#', '')
  if (name === PRODUCT_LINK_ANCHORS.EFAX_RESULT) return featureFlags?.enableManualEfax
  if (name === PRODUCT_LINK_ANCHORS.EMAIL_RESULT) return featureFlags?.enableEmailResult
  if (name === PRODUCT_LINK_ANCHORS.FIND_A_DOCTOR) return featureFlags?.enableFindADoctor

  return [
    PRODUCT_LINK_ANCHORS.DOCTOR_DISCUSSION_GUIDE,
    PRODUCT_LINK_ANCHORS.FIND_A_SPECIALIST
  ].includes(name)
}
