export class BaseService {}

/*
 * Add all the items what you want to make available
 * in each services in the app
 */
BaseService.setupService = (store, router) => {
  BaseService.prototype.$router = router
  BaseService.prototype.$store = store
}
