<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="params.width"
    :height="params.height"
    :viewBox="params.viewBox"
    :stroke="params.stroke"
    role="presentation"
    class="icon-base"
    v-on="$listeners"
  >
    <title v-if="params.name">{{ $t(params.name) }}</title>

    <g :fill="params.color">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconBase',
  props: {
    params: {
      default: () => {
        return {
          width: '18',
          height: '18',
          viewBox: '0 0 18 18',
          name: '',
          color: '',
          stroke: ''
        }
      },
      type: Object
    },
    alt: {
      type: String,
      default: ''
    }
  }
}
</script>
