export default {
  idleTimeoutMinutes: 15, // using through config store
  // excludedStates: [], // using through config store, renamed to "footerExcludedStates"

  companyName: 'Company', // using through config store
  companyCountryIsoCode: 'US', // using through config store
  companyPhone: '8881237575', // using through config store

  // through direct (dtc) prescreen flow, if the user changes the url orgId or prodId or refreshes
  // the page we will force to them to start again from company order page
  companyOrderPageWebUrl: 'http://google.com', // using through config store

  // Customers can choose either supportEmail or supportPhone. One of them must be '' for now
  supportEmail: 'support@ixlayer.com', // using through config store
  supportPhone: '', // using through config store
  ixlayerWebUrl: 'https://ixlayer.com/', // using through config store

  // i18nEnabled has been depricated. From now, the languages array is responsible for the available languages.
  // It MUST be < languages: ['en'] > for now for all PPV2 and reference as well.
  // later, when translation files are ready and updated, we can add other languages, like languages: ["en", "es"]
  // The language selector in mainheader, and PPV3 /settings/profile is shown/hidden based on the languages array's length.
  languages: ['en'], // using through config store

  // allowMultipleInstancesOfProduct has been terminated. Old config, not relevant anymore
  // allowMultipleInstancesOfProduct: true,

  routerFallback: {
    signedIn: {
      path: 'dashboard'
    },
    signedOut: {
      path: 'home',
      name: 'Home'
    }
  }, // using through config store

  // navbar footer logo can have href parameter to redirect on-click to another website - this is optional, leave it empty if no need this feature
  // navbar header optionally have username after signing in. Set hasUserName Boolean if need to display user name in header
  /* navbar: { // using through config store
    logo: {
      signedOutRouterLinkTo: 'Home',
      footer: {
        href: 'https://ixlayer.com/'
      }
    },
    hasUserName: true
  }, */

  // dropdownItems can have either 'to' or 'href' parameters
  mainHeaderSignedOutMenuItems: [
    { links: [{ to: 'Home', text: 'header.home' }] },
    { links: [{ to: 'Product', text: 'header.product' }] },
    {
      dropdownItems: [
        {
          href: 'https://ixlayer.com/',
          color: '#5cb3fd',
          hasIcon: true,
          text: 'Ixlayer'
        }
      ],
      text: 'header.more_with_icon'
    },
    {
      links: [
        {
          authUrlSuffix: '?redirect_to=kit-registration/test',
          text: 'header.kit_activation'
        }
      ]
    }
  ], // using through config store
  mainHeaderProfilePageMenuItems: [
    { to: 'Profile', text: 'header.profile' },
    { to: 'Logout', text: 'btn.logout' }
  ], // using through config store
  mainHeaderProfilePageMenuItemsV3: [
    {
      dropdownItems: [
        { to: 'ProfileV3', text: 'header.profile' },
        { to: 'Insurance', text: 'header.insurance', featureFlag: 'insurance' },
        { to: 'Guardian', text: 'header.guardian' },
        { to: 'Security', text: 'header.security' }
      ],
      text: 'header.settings',
      to: 'ProfileV3'
    },
    { to: 'Orders', text: 'header.purchase_history' },
    { to: 'Logout', text: 'btn.logout' }
  ], // using through config store

  footerSignedInMenuItems: [
    { links: [{ to: 'FAQ', text: 'footer.faq' }] },
    { links: [{ mailTo: 'support@ixlayer.com', text: 'footer.contact_us' }] }
  ], // using through config store
  footerSignedOutMenuItems: [
    {
      links: [{ to: 'Home', text: 'footer.home' }]
    },
    {
      links: [
        { to: 'FAQ', text: 'footer.faq' },
        { phone: '8888888888', text: '8888888888' },
        { mailTo: 'support@ixlayer.com', text: 'footer.contact_us' }
      ]
    },
    {
      links: [
        { to: 'Legal', text: 'footer.terms_of_service', elmId: 'terms' },
        { to: 'Legal', text: 'footer.privacy_policy', elmId: 'privacy' }
      ],
      title: 'footer.links_title.legal'
    }
  ], // using through config store
  footerMenuItemsV3: [
    {
      links: [
        { to: 'Legal', text: 'footer.terms_of_service', elmId: 'terms' },
        { to: 'Legal', text: 'footer.privacy_policy', elmId: 'privacy' }
      ],
      title: '{companyName}'
    },
    {
      links: [
        {
          href: 'https://ixlayer.com/terms-of-service/',
          text: 'footer.terms_of_service',
          elmId: 'terms'
        },
        {
          href: 'https://ixlayer.com/privacy-policy/',
          text: 'footer.privacy_policy',
          elmId: 'privacy'
        }
      ],
      title: 'footer.links_title.ixlayer'
    },
    {
      links: [
        {
          href: 'https://admin.reference.ixlayer.com/login',
          text: 'footer.admin_portal'
        }
      ],
      title: 'footer.links_title.administrators'
    }
  ], // using through config store

  iconColorV2: {
    // using through config store
    selectedMark: '#2ED5AD',
    virtual: '#2ED5AD',
    phone: '#2ED5AD',
    questionMark: '#FFFFFF',
    statusTracker: {
      default: '#C9C9C9',
      completed: '#FFFFFF',
      active: '#FFFFFF'
    }
  },

  /* !!! DEPRECATED in pp v3
   * use the remote theme
   * "color": {
   *   "app-background": "#FFF",
   *   "footer-background": "#FFF"
   * },
   * branding: {
   *   backgroundImage: {
   *     mobile: <url>
   *     desktop: <url>
   *   }
   * }
   */
  clientStyles: {
    // using through config store
    bgColor: '#FFF',
    footerBgColor: '#F8F8F8',
    bgImg: null, // bgImg: `url(${require('@/assets/img/backgrounds/acme.svg')})`,
    bgImgMobile: null // bgImgMobile: `url(${require('@/assets/img/backgrounds/acme-mobile.svg')})`
  },

  // using through config store
  /* genders: [
    { text: 'form.gender.male', answer_id: 'male', id: 'male' },
    { text: 'form.gender.female', answer_id: 'female', id: 'female' },
    { text: 'form.gender.other', answer_id: 'other', id: 'other' },
    { text: 'form.gender.unknown', answer_id: 'unknown', id: 'unknown' }
  ], */

  // profileAgeLimitToNotHaveGuardian: 18, // using through config store
  // show auxiliary profile form fields like MRN and Group, not only on Profile page, but on SignupProfile as well
  // showAuxProfileFormFields: false, // replaced with showAuxProfileFormFields flag from backend
  // productStatusPageSize: 5,  // using through config store
  // collection locations for kit registration. If collectionLocation array is empty, then 'At home' will be used as a default
  // collectionLocation: [], // using through config store, renamed to kit_reg_collection_location

  // productConsentLabelPrefix: 'product_consent', // moved to constants/document.js
  // prescreenConsentLabelPrefix: 'product_consent', // moved to constants/document.js
  // researchConsentLabelPrefix: 'research_consent', // moved to constants/document.js
  // termsLabelPrefix: 'terms', // moved to constants/document.js
  // privacyPolicyLabelPrefix: 'privacy_policy', // moved to constants/document.js

  gmapKey: 'AIzaSyDzUJ3gFMrYoATkMrY0128fhl2RuK1XfTc', // using through config store

  // isAddressInPrescreenProfile: true, // using through config store

  // if showSignupAccessCode is true, than dtcOrganization can be integer or null.
  // If dtcOrganization === null than the signup access code is required.

  // showSignupAccessCode: true, // using through config store
  /* dtcOrganization: { // using through config store
    id: 1,
    accessCode: '1'
  }, */

  reCaptchaSiteKey: '6Lc8suUUAAAAAGmxoSkVOQiPX3EImFzTbp5V1MUL', // using through config store

  zipPattern: /^\b\d{5}(-\d{4})?\b$/, // using through config store
  minSupportedYear: 1900,

  // addressValidationCredentials: '12650088488277732', // obsolete config, not used anymore

  stripePublishableKey:
    'pk_test_51JKTmxFDdRLuKFHxj6RnBeoM68fnCxDSELdLDB81qHhLZt82pU5q1vcqiHUJzSpUCtVjbt2DAgmb5uGUWI786VHR00k4ne0J5a', // using through config store

  // Use sessions instead of localStorage. Session data is cleared when a browser window is
  // closed vs localStorage which persists the data even after window is closed
  useSessionStorage: false, // using through config store

  passwordPolicyMinLength: 6, // using through config store

  // This is a switch for all Prescreen Questionnaire sentry state logs. Use it for testing/debugging purposes
  enableSentryLogs: {
    prescreen: false
  }

  // Feature flags and switches for pages, consent popups etc. Whether the given platform supports:
  // - patient insurance
  // isInsuranceSupported: true, // replaced with insurance flag from backend
  // canUploadInsuranceDocuments: false, // replaced with canUploadInsuranceDocuments flag from backend
  // - showing consent popup for ordering/prescreen flow
  // hasPrescreenConsent: true,  // replaced with hasPrescreenConsent flag from backend
  // - having registration page, links to that page in header or in login page helperbox
  // hasUserRegistration: true, // replaced with allowUserReg flag from backend
  // - having signed out prescreen flow, starting on PatientStart page, having link from header or from other signed out pages
  // hasSignedOutPrescreen: true // replaced with hasSignedOutPrescreen flag from backend
  // - passport field on patient profile
  // allowPassport: false, // replaced with showPassport flag from backend
  // isRresearchConsentDuringKitRegistration: false, // replaced with researchConsentDuringKitRegistration flag from backend
  // isSurveySupported: false // replaced with surveySupported flag from backend
}
