// Globally register all Base components for convenience, because they
// are used very frequently in PP v3.

import Vue from 'vue'

const baseComponents = import.meta.glob('./**/Base*.vue')
for (const component in baseComponents) {
  // Get the PascalCase version of the component name
  const componentName = component
    // Split the path and keep only the last part
    // e.g ./ui-components/BaseHeading/BaseHeading.vue -> BaseHeading.vue
    .split('/')
    .pop()
    // Remove the file extension from the end
    // e.g BaseHeading.vue -> BaseHeading
    .replace(/\.\w+$/, '')

  // Globally register the component
  Vue.component(componentName, baseComponents[component])
}
