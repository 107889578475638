import { camelCase } from 'lodash-es'

export const isStateInTransitionHistory = (productStatus, stateToCheck) => {
  if (!productStatus) return false
  return productStatus.transition_history?.some((item) => item.to_state === stateToCheck)
}

export const getWorkflowStateContentOverride = (
  workflowState,
  product,
  stepStatus,
  cmsOverrides = {},
  configOverrides = {}
) => {
  const getContent = (source, keys, stepStatus) => {
    // Find matching tag: sku first, then product name
    const key = Array.isArray(keys) ? keys.find((key) => typeof source[key] !== 'undefined') : keys
    const value = source[key]

    return value?.statuses?.[stepStatus] && Object.keys(value.statuses[stepStatus]).length > 0
      ? value?.statuses[stepStatus]
      : value
  }

  const stateKey = camelCase(workflowState)
  const productLevelKeys = [`${stateKey}|sku:${product.sku}`, `${stateKey}|${product.name}`]

  const productLevelCmsOverride = getContent(cmsOverrides, productLevelKeys, stepStatus)
  const cmsOverride = getContent(cmsOverrides, stateKey, stepStatus)
  const productLevelConfigOverride = getContent(configOverrides, productLevelKeys, stepStatus)
  const configOverride = getContent(configOverrides, stateKey, stepStatus)

  return {
    title:
      productLevelCmsOverride?.title ||
      cmsOverride?.title ||
      productLevelConfigOverride?.title ||
      configOverride?.title ||
      '',
    headline:
      productLevelCmsOverride?.headline ||
      cmsOverride?.headline ||
      productLevelConfigOverride?.headline ||
      configOverride?.headline ||
      '',
    text:
      productLevelCmsOverride?.text ||
      cmsOverride?.text ||
      productLevelConfigOverride?.text ||
      configOverride?.text ||
      '',
    instructionsButton:
      productLevelCmsOverride?.instructionsButton ||
      cmsOverride?.instructionsButton ||
      productLevelConfigOverride?.instructionsButton ||
      configOverride?.instructionsButton ||
      ''
  }
}
