import storage from '@/classes/Storage.js'
import { IS_AUTHENTICATED } from '@/constants/profile.js'
import { getConfig } from '@/use/useConfig.js'
import { CONFIG } from '@/constants/config.js'

export const isAuthenticated = () => Boolean(storage.getStoredItem(IS_AUTHENTICATED))
export const getFallbackPath = () =>
  isAuthenticated()
    ? getConfig(CONFIG.ROUTER_FALLBACK)?.signedIn?.path
    : getConfig(CONFIG.ROUTER_FALLBACK)?.signedOut?.path

export const removeQueryParam = () => {
  history.pushState(null, null, window.location.href.split('?')[0])
}
