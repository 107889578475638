import { trackPageView } from '@/utils/analytics/pageview.js'
import { getGlobalParameters } from '@/utils/analytics/events.js'
import { ANALYTICS_USER_ID } from '@/constants/analytics.js'

export const installGA4 = (config, currentRoute, store, userId, isCookieConsentEnabled = false) => {
  // Respect Do Not Track header
  if (navigator.doNotTrack === '1') return

  const tagConfigurations = Array.isArray(config) ? config : [{ measurementId: config }]
  if (tagConfigurations.length === 0) return

  const existingTag = document.getElementById('gtag-script')
  if (existingTag !== null) existingTag.remove()

  const gtagScript = document.createElement('script')
  gtagScript.onload = () => {
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/config
    // Disable the default page view event, because we want to do some sanitization
    tagConfigurations.forEach((config) => {
      window.gtag('config', config.measurementId, getGlobalParameters(currentRoute, store, config))
    })

    if (!currentRoute.meta.hasManualPageViewTracking) {
      trackPageView(currentRoute)
    }
  }
  gtagScript.id = 'gtag-script'
  gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${tagConfigurations[0].measurementId}`
  gtagScript.async = true
  if (isCookieConsentEnabled) {
    gtagScript.type = 'text/plain'
    gtagScript.dataset.categories = 'analytics'
  }

  document.head.append(gtagScript)

  window.gaTagConfigurations = tagConfigurations
  window.dataLayer = window.dataLayer || []
  window.gtag = function () {
    window.dataLayer.push(arguments)
  }
  window.gtag('js', new Date())
}

/**
 * @link https://developers.google.com/analytics/devguides/collection/ga4/reference/config#user_id
 *
 * @param userId
 */
export const setGA4UserId = (userId) => {
  if (!window.gtag) return

  window.gaTagConfigurations
    .filter((config) => !config.limited)
    .forEach((config) =>
      window.gtag('config', config.measurementId, { [ANALYTICS_USER_ID]: userId })
    )
}
