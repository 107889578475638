import { store } from '@/store/index.js'
import { i18n } from '@/plugins/i18n.js'

const SelfTestReportWizard = () =>
  import('@/components/views/SelfTestReportWizard/SelfTestReportWizard.vue')
const TestSelection = () =>
  import('@/components/views/SelfTestReportWizard/TestSelection/TestSelection.vue')
const SelfTestReport = () =>
  import('@/components/views/SelfTestReportWizard/SelfTestReport/SelfTestReport.vue')

const selfTestWizard = () => store.getters.featureFlags.selfTestWizard

export default {
  path: 'upload-result',
  component: SelfTestReportWizard,
  beforeEnter: (to, from, next) =>
    selfTestWizard() ? next() : next({ name: 'TestDashboard', params: { lang: to.params.lang } }),
  children: [
    {
      path: 'select-product/:orgId',
      name: 'TestSelection',
      component: TestSelection,
      props: true,
      meta: {
        title: i18n.t('headful_title.test_selection'),
        requiresAuth: true
      }
    },
    {
      path: 'enter-result/:orgId/:productId',
      name: 'SelfTestReport',
      component: SelfTestReport,
      props: true,
      meta: {
        title: i18n.t('headful_title.self_test_report'),
        isFooterHidden: true,
        requiresAuth: true
      }
    }
  ],
  meta: { requiresAuth: true }
}
