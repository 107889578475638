import Vue, { getCurrentInstance } from 'vue'
import { onBeforeRouteLeave } from 'vue-router/composables'
import PopupV3 from '@/components/ui-components/Popup/PopupV3.vue'

export default function useBackButtonConfirmationPopup({
  blockedRouteNames,
  title,
  text,
  buttons
}) {
  const instance = getCurrentInstance()
  const vm = instance?.proxy

  if (!vm) {
    throw new Error(
      'useBackButtonConfirmationPopup only works during setup or Lifecycle Hooks.' +
        'Please call useBackButtonConfirmationPopup() on setup and use the instance afterward.' +
        'Ref: https://v3.vuejs.org/api/composition-api.html#getcurrentinstance'
    )
  }

  const popupUserActionBtns = [
    {
      title: buttons.stayHerePrimary.title
    },
    {
      title: buttons.goAwaySecondary.title,
      isGhost: true
    }
  ]

  const addPopup = (unregisterRouteLeave) => {
    const PopupClass = Vue.extend(PopupV3)
    const instance = new PopupClass({
      propsData: {
        title,
        text1: text,
        userActionBtns: popupUserActionBtns
      },
      parent: vm
    })
    instance.show = () => {
      instance.$mount()
      vm.$root.$el.appendChild(instance.$el)
    }

    const destroy = () => {
      vm.$root.$el.removeChild(instance.$el)
      instance.$destroy()
    }

    // Primary button - hide the popup and stay where we are
    popupUserActionBtns[0].onClick = destroy

    // Secondary button action
    popupUserActionBtns[1].onClick = () => {
      // unblock routing
      unregisterRouteLeave()
      // hide the popup
      destroy()
      buttons.goAwaySecondary.onClick()
    }
    return instance
  }

  const unregisterRouteLeave = onBeforeRouteLeave((to, from, next) => {
    if (blockedRouteNames.includes(to.name)) {
      const popup = addPopup(unregisterRouteLeave)
      popup.show()
      // Block routing
      return next(false)
    }
    // Cleanup the hook registered by this composable
    unregisterRouteLeave()
    // Let the routing happen
    return next()
  })
}
