export const installTermlyTag = (websiteUuid) => {
  const isSameWebsiteUuidInstalled = window.termlyWebsiteUuid === websiteUuid
  if (isSameWebsiteUuidInstalled) return

  const existingTag = document.getElementById('termly-consent-banner')
  if (existingTag !== null) existingTag.remove()

  if (!websiteUuid) return

  window.termlyWebsiteUuid = websiteUuid

  // https://support.termly.io/en/articles/8952694-termly-cmp-embed-script-versions
  // AutoBlocker is turned off, because we block scripts manually: https://support.termly.io/en/articles/7904710-blocking-javascript-third-party-cookies-manually
  const consentBannerScript = document.createElement('script')
  consentBannerScript.id = 'termly-consent-banner'
  consentBannerScript.src = `https://app.termly.io/resource-blocker/${websiteUuid}`

  document.head.append(consentBannerScript)
}
