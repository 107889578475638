import {
  ANALYTICS_GROUP_DEFAULT,
  ANALYTICS_DIMENSION_FLOW_NAME,
  ANALYTICS_DIMENSION_INTERPRETATION,
  ANALYTICS_DIMENSION_METHOD,
  ANALYTICS_DIMENSION_PRODUCT_STATUS_ID,
  ANALYTICS_DIMENSION_TEST_NAME,
  ANALYTICS_EVENT_METHOD_EMAIL,
  ANALYTICS_GROUP_LIMITED,
  ANALYTICS_USER_ID
} from '@/constants/analytics.js'
import { getFlowName } from '@/utils/analytics/flows.js'
import { isExternalLink } from '@/utils/domUtils.js'

export const getGlobalParameters = (route, store, config) => {
  const testName = route.params.productId
    ? store.getters.productList?.find(
        (product) => product.id === parseInt(route.params.productId, 10)
      )?.name
    : ''
  const productStatusId = route.params.productStatusId ?? ''
  const flowName = getFlowName(route)

  const parameters = {
    send_page_view: false,
    // https://developers.google.com/tag-platform/gtagjs/routing
    groups: config.limited ? ANALYTICS_GROUP_LIMITED : ANALYTICS_GROUP_DEFAULT,
    [ANALYTICS_DIMENSION_FLOW_NAME]: flowName,
    [ANALYTICS_DIMENSION_TEST_NAME]: testName,
    [ANALYTICS_DIMENSION_PRODUCT_STATUS_ID]: productStatusId
  }

  if (!config.limited && store.getters.profile?.id) {
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/config#user_id
    parameters[ANALYTICS_USER_ID] = store.getters.profile.id
  }

  return parameters
}

const EXCLUDED_PARAMETERS_FOR_LIMITED_GROUP = [ANALYTICS_DIMENSION_INTERPRETATION]

/**
 * @link https://developers.google.com/analytics/devguides/collection/ga4/reference/events
 *
 * @param name
 * @param parameters
 */
export const trackEvent = (name, parameters = {}) => {
  if (!window.gtag) return

  // Exclude parameters for properties in the limited group
  if (
    window.gaTagConfigurations?.some((config) => config.limited) &&
    Object.keys(parameters).some((parameter) =>
      EXCLUDED_PARAMETERS_FOR_LIMITED_GROUP.includes(parameter)
    )
  ) {
    const limitedParameters = Object.entries(parameters).filter(
      ([parameter]) => !EXCLUDED_PARAMETERS_FOR_LIMITED_GROUP.includes(parameter)
    )

    window.gtag('event', name, {
      ...Object.fromEntries(limitedParameters),
      send_to: ANALYTICS_GROUP_LIMITED
    })
    window.gtag('event', name, { ...parameters, send_to: ANALYTICS_GROUP_DEFAULT })

    return
  }

  window.gtag('event', name, {
    send_to: [ANALYTICS_GROUP_DEFAULT, ANALYTICS_GROUP_LIMITED],
    ...parameters
  })
}

export const trackOutboundClick = (url, element = null, parameters = {}) => {
  trackEvent('click', {
    link_id: element?.id ?? '',
    link_classes: element?.classList?.toString() ?? '',
    link_url: url.href.replace(/tel:.*/, 'Phone number'),
    link_domain: url.host.replace(/^www\./, ''),
    outbound: true,
    ...parameters
  })
}

export const trackOutboundLink = (linkElement, parameters = {}) => {
  if (!isExternalLink(linkElement.href)) {
    return
  }

  const url = new URL(linkElement)
  trackOutboundClick(url, linkElement, parameters)
}

/**
 * @link https://developers.google.com/analytics/devguides/collection/ga4/reference/events#login
 *
 * @param method
 */
export const trackLogin = (method = ANALYTICS_EVENT_METHOD_EMAIL) => {
  trackEvent('login', {
    [ANALYTICS_DIMENSION_METHOD]: method
  })
}

/**
 * @link https://developers.google.com/analytics/devguides/collection/ga4/reference/events#sign_up
 *
 * @param method
 */
export const trackSignUp = (method = ANALYTICS_EVENT_METHOD_EMAIL) => {
  trackEvent('sign_up', {
    [ANALYTICS_DIMENSION_METHOD]: method
  })
}

/**
 * @link https://developers.google.com/analytics/devguides/collection/ga4/exceptions
 *
 * @param description
 * @param fatal
 * @param parameters
 */
export const trackException = (description, fatal = false, parameters = {}) => {
  trackEvent('exception', {
    description,
    fatal,
    ...parameters
  })
}
