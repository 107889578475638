import { useRouter } from 'vue-router/composables'

import { AUTH_LOGOUT } from '@/constants/profile.js'
import {
  FETCH_PRODUCT_LIST,
  RESET_PRODUCT_STATUS_IN_PROGRESS_LIST,
  RESET_PRODUCT_STATUS_OPEN_ORDER_LIST,
  RESET_PRODUCT_STATUS_RESULT_READY_LIST
} from '@/store/products.js'
import { RESET_SURVEY_LIST } from '@/store/surveys.js'
import { Trans } from '@/plugins/Translation.js'
import { GET_THEME } from '@/store/theme.js'
import { GET_FEATURE_FLAGS } from '@/store/feature.js'
import { getConfig } from '@/use/useConfig.js'
import { CONFIG } from '@/constants/config.js'
import { GET_CONFIGS } from '@/store/config.js'
import { store } from '@/store/index.js'

export function useLogout() {
  const router = useRouter()

  const handleLogout = async (skipGoToLogin = false) => {
    document.querySelector('body').classList.remove('locked')
    store.commit(RESET_PRODUCT_STATUS_RESULT_READY_LIST)
    store.commit(RESET_PRODUCT_STATUS_IN_PROGRESS_LIST)
    store.commit(RESET_PRODUCT_STATUS_OPEN_ORDER_LIST)
    store.commit(RESET_SURVEY_LIST)

    await Promise.all([
      store.dispatch(GET_FEATURE_FLAGS),
      store.dispatch(GET_CONFIGS),
      store.dispatch(GET_THEME)
    ])
    // Depends on config call and therefore cannot be parallelized
    await store.dispatch(FETCH_PRODUCT_LIST, getConfig(CONFIG.ORGANIZATION) || null)

    if (skipGoToLogin) return
    return router.push(Trans.i18nRoute({ name: 'Login' })).catch(() => {})
  }

  const logout = (skipGoToLogin = false) => {
    return (
      store
        .dispatch(AUTH_LOGOUT)
        .then(() => {
          handleLogout(skipGoToLogin).catch(() => {})
        })
        // In case you trying to log out with an expired token
        // Say you've reset your password on a different tab, and then you want to make authenticated requests
        // on your original tab. At this time your backend sessions have already been invalidated due to the password
        // change, but your token is now invalid, and therefore you cannot log out.
        .catch(() => {
          handleLogout(skipGoToLogin).catch(() => {})
        })
    )
  }

  return {
    logout
  }
}
