import themeService from '@/classes/ThemeService.js'

export const GET_THEME = 'GET_THEME'
const GET_THEME_SUCCESS = 'THEME_RECEIVED'
const GET_THEME_ERROR = 'GET_THEME_ERROR'

const initTheme = () => ({ themeStyle: null, themeLoadingError: null })
const state = {
  ...initTheme()
}

const getters = {
  getThemeValue: (state) => (key, fallback) => state.themeStyle?.[key] || fallback,
  primaryThemeColor: (state) => state.themeStyle?.['--color-primary'],
  isThemeLoaded: (state) => !!state.themeStyle,
  themeLoadingError: (state) => state.themeLoadingError
}

const actions = {
  [GET_THEME]: async ({ commit }) => {
    try {
      const themeStyle = await themeService.load()
      commit(GET_THEME_SUCCESS, themeStyle)
      return themeStyle
    } catch (err) {
      commit(GET_THEME_ERROR, err)
      throw err
    }
  }
}

const applyThemeDesign = (themeStyle) => {
  // CSS define CSS variables in the format "--button-primary-default-font-size: 20px;" so turn the object to a list of key-value pairs
  const styleList = Object.keys(themeStyle).map((name) => `${name}: ${themeStyle[name]}`)
  // Apply the CSS variables
  document.getElementById('app-style').innerHTML = `:root { ${styleList.join(';')} }`
}

const mutations = {
  [GET_THEME_SUCCESS]: (state, themeStyle) => {
    state.themeStyle = themeStyle
    state.themeLoadingError = null
    applyThemeDesign(themeStyle)
  },
  [GET_THEME_ERROR]: (state, err) => {
    state.themeStyle = null
    state.themeLoadingError = err
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
