import IXLayerAPI from '@/classes/IXLayerAPI.js'
import { parseObjectValues, transformToCamelCase } from '@/utils/objectUtils.js'
import defaultConfigs from '@/config/defaultConfigs.js'
import { i18n } from '@/plugins/i18n.js'
import { errorHandler } from '@/utils/errorUtils.js'
import { CONFIG } from '@/constants/config.js'

export const GET_CONFIGS = 'GET_CONFIGS'
const SET_CONFIGS = 'SET_CONFIGS'
const SET_CONFIG_PROMISE = 'SET_CONFIG_PROMISE'
const SET_CONFIGS_REQUEST_FAILED = 'SET_CONFIGS_REQUEST_FAILED'

const isValidConfigValue = (value) => {
  return value !== undefined && value !== ''
}

const state = {
  configs: {},
  configPromise: null,
  configsRequestFailed: false
}

const getters = {
  configs: (state) => state.configs,
  configsRequestFailed: (state) => state.configsRequestFailed,
  /*
    In case when use_frontend_configs FF is ON, these configs come from the BE, and we need to make
    sure they can have different values, including false and null. If the 'key' is missing in the
    BE, the value will be undefined, if the 'key' exists without a value, then the value will be
    an empty string. In some cases to avoid to break the UI by missing configs, we can use the
    defaultConfigs.js file, and lookup there for certain configs, which must have a value.
  */
  getConfig: (state) => (key) =>
    (isValidConfigValue(state.configs[key]) ? state.configs[key] : defaultConfigs[key]) ?? '',

  linkedInConversions: (state) =>
    state.configs[CONFIG.LINKED_IN_CONVERSIONS] || defaultConfigs[CONFIG.LINKED_IN_CONVERSIONS]
}

const actions = {
  [GET_CONFIGS]: async ({ commit, state, getters }) => {
    const loadConfigs = async () => {
      try {
        let data = {}

        const response = await IXLayerAPI.getConfigs()
        data = transformToCamelCase(
          parseObjectValues({
            ...response.data?.org_config,
            organization: response.data?.organization,
            help_center_url: response.data?.help_center_url
          })
        )
        // TODO: Moving forward we might need to add some validation (like Schema) here, especailly for header/footer menu items
        commit(SET_CONFIGS, { data, getters })
        commit(SET_CONFIGS_REQUEST_FAILED, false)
      } catch (err) {
        // TODO: we should check with BE if we can standardize the error messages, and use a key instead of string for look up
        if (
          (err?.response?.status === 400 &&
            err.response.data?.message.includes('token is missing or invalid')) ||
          (err?.response?.status === 404 &&
            err.response.data?.message.includes('no frontend config found'))
        ) {
          errorHandler({
            toastedMessage: i18n.t('error.missing_frontend_config'),
            sentryErrMessage: `FAILED DUE TO ${err.response.data?.message}`,
            shouldSentryThrowError: false
          })
          return
        }
        commit(SET_CONFIGS_REQUEST_FAILED, true)
        throw new Error(err)
      } finally {
        commit(SET_CONFIG_PROMISE, null)
      }
    }

    // Promise cache to make sure the request is not sent multiple times while running
    if (state.configPromise) {
      return state.configPromise
    }
    const promise = loadConfigs()
    commit(SET_CONFIG_PROMISE, promise)
    return promise
  }
}

const mutations = {
  [SET_CONFIGS]: (state, attr) => {
    state.configs = attr.data
  },
  [SET_CONFIG_PROMISE]: (state, promise) => {
    state.configPromise = promise
  },
  [SET_CONFIGS_REQUEST_FAILED]: (state, value) => {
    state.configsRequestFailed = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
