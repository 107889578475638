import storage from '@/classes/Storage.js'
import { store } from '@/store/index.js'
import { IS_AUTHENTICATED } from '@/constants/profile.js'

const isAuthenticated = () => Boolean(storage.getStoredItem(IS_AUTHENTICATED))
const defaultSignedInRouterLinkTo = () => store.getters.defaultSignedInRouterLinkTo

const routeTo = (authenticatedRouteName, unauthenticatedRouteName, originalRoute) => {
  const { params, meta, query } = originalRoute
  return {
    params,
    meta,
    query,
    name: isAuthenticated() ? authenticatedRouteName : unauthenticatedRouteName
  }
}

export default [
  {
    path: 'go-home',
    name: 'GoHome',
    redirect: (to) => {
      return routeTo(defaultSignedInRouterLinkTo(), 'Home', to)
    }
  },
  {
    path: 'order-test-start',
    name: 'OrderTestStart',
    redirect: (to) => {
      return routeTo('PrescreenKitSelection', 'PatientStart', to)
    }
  },
  {
    path: 'order-test-consent',
    name: 'OrderTestConsent',
    redirect: (to) => {
      return routeTo('PrescreenConsent', 'PatientPrescreenConsent', to)
    }
  },
  {
    path: 'order-test-health-history',
    name: 'OrderTestQuestionnaire',
    redirect: (to) => {
      const flagsEnabled =
        store.getters.featureFlags.hasPrescreenConsent &&
        store.getters.featureFlags.consentBeforeQuestionnairePatientOrder
      const orgFlagsEnabled =
        store.getters.orgByAccessCode?.flags?.hasPrescreenConsent &&
        store.getters.orgByAccessCode?.flags?.consentBeforeQuestionnairePatientOrder
      if ((flagsEnabled || orgFlagsEnabled) && !to.query.consented) {
        return routeTo('OrderTestConsent', 'OrderTestConsent', to)
      }
      return routeTo('Prescreen', 'PatientPrescreen', to)
    }
  },
  {
    path: 'order-test-final',
    name: 'OrderTestFinal',
    redirect: (to) => {
      return routeTo('PrescreenFinal', 'PatientFinal', to)
    }
  },
  {
    path: 'order-test-checkout',
    name: 'OrderTestCheckout',
    redirect: (to) => {
      return routeTo('PrescreenCheckout', 'PatientCheckout', to)
    }
  },
  // V3 GetLab scheduling
  {
    path: 'order-test-scheduling',
    name: 'OrderTestScheduling',
    redirect: (to) => {
      return routeTo('PrescreenOrderScheduling', 'PatientOrderScheduling', to)
    }
  }
]
