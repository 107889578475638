import { flattenObject } from '@/utils/objectUtils.js'
import IXLayerAPI from '@/classes/IXLayerAPI.js'
import { get, isArray, isNull, isString, merge } from 'lodash-es'
import { url } from 'vuelidate/lib/validators'
import { getConfig } from '@/use/useConfig.js'

import { BREAKPOINTS } from '@/constants/breakpoints.js'
import { CONFIG } from '@/constants/config.js'

const breakpointNames = Object.keys(BREAKPOINTS)

const THEME_CACHE_NAME = 'THEME_CACHE'

export default {
  _cacheTheme(cssValues) {
    try {
      localStorage.setItem(THEME_CACHE_NAME, JSON.stringify(cssValues))
    } catch (e) {
      // Can be silent, in this case the caching failed probably because localStorage is disabled
    }
  },

  _loadCachedTheme() {
    try {
      const themeJSONStr = localStorage.getItem(THEME_CACHE_NAME)
      return JSON.parse(themeJSONStr)
    } catch (e) {
      // Can be silent, in this case the caching failed probably because localStorage is disabled
      return null
    }
  },

  async _loadAndProcessTheme() {
    const themeResponse = await IXLayerAPI.getTheme()
    const theme = {
      ...themeResponse.theme,
      branding: themeResponse.branding
    }
    const cssVariables = this.themeToCSSConfig(theme)

    // Add any special transformation here if needed, e.g.
    // cssVariables['app-background-image'] = `url(${require(theme.branding.backgroundImage)})`

    // THIS IS TEMPORARILY! This style info stored tmp in app-config should be returned by the backend
    merge(cssVariables, this.getAppClientStyle())

    return cssVariables
  },

  async load() {
    try {
      const themeCSSValues = await this._loadAndProcessTheme()
      this._cacheTheme(themeCSSValues)
      return themeCSSValues
    } catch (e) {
      // Try to fallback to the latest cached theme
      const theme = this._loadCachedTheme()
      if (theme) return theme
      throw e.message || e
    }
  },

  /**
   * A value in theme can be a valid CSS value or a reference to a theme variable.
   * This function is responsible to resolve the variable references to the actual value
   *
   * Example theme definition WITH a reference to a theme variable:
   * {
   *   text: {
   *     font: "variables.fonts.body",
   *     border: "1px variables.linetype variables.color.primary"
   *     color: "#FFFFFF"
   *   }
   * }
   *
   * so this fn is responsible to replace the values with "variables.xxx.xxx.xxx" format
   * to an actual theme variable value:
   *   text: {
   *     font: "Arial",
   *     color: "#FFFFFF"
   *   }
   *
   * @returns {any} the resolved CSS value
   */
  _resolveVariableReference(theme, cssValue) {
    if (!isString(cssValue)) return cssValue
    return cssValue.replace(/[^\s"]*variables*.[^\s"]+/gi, (match) => {
      const resolvedValue = get(theme, match)
      return resolvedValue ?? ''
    })
  },

  /**
   * A value in theme can be a valid URL string, e.g. referencing a logo or other image.
   * Because var() interpolation cannot be used with url() due to legacy reasons, we need to so the url() interpolation upfront here.
   *
   * Example theme definition WITH a reference to a theme variable:
   * {
   *   logo: "https://ixlayer.com/logo.png"
   * }
   *
   * this fn is responsible to resolve the add the URL fn around the URL string:
   * {
   *   logo: "url(https://ixlayer.com/logo.png)"
   * }
   *
   * @returns {any} the resolved CSS value
   */
  _resolveURLStrings(theme, cssValue) {
    if (!isString(cssValue)) return cssValue
    if (url(cssValue)) return `url(${cssValue})`
    return cssValue
  },

  /*
   *  To resolve the mobile first "array like" definition of the responsive values.
   *  Let's explain by an example:
   *  E.g. “font-size”: “16px” means: 16px font size universally, not depending on the screen width.
   *  But font-size: [“12px”, “13px”, “14px”, “15px”, “17px”, "18px" ] means:
   *     screen-width < sm -> 12px font-size
   *     sm <= screen-width < md → 13px font-size
   *     md <= screen-width < lg → 14px font-size
   *     lg <= screen-width < xl → 15px font-size
   *     xl <= screen-width < xxl → 17px font-size
   *     xxl <= screen-width -> 18px font-size
   *
   *  If you want to skip a breakpoint, you can use the value null:
   *     “font-size”: [“12px”,  null, “20px“, null, null] - on “small devices” (< md) it is 12px
   *     but on screens larger then “md” size it will be 20px. It has the same meaning like [“12px”,  null, “20px“].
   *     **This is a mobile first definition, so mobile MUST be defined!**
   *
   *  So, this function turns the `“font-size”: [“12px”,  null, “20px“, null, null, null] OR
   *                          the `“font-size”: [“12px”,  null, “20px“]
   *  ` to
   *      --font-size: "12px"
   *      --font-size-sm: "12px"
   *      --font-size-md: "20px"
   *      --font-size-lg: "20px"
   *      --font-size-xxl: "20px"
   */

  _resolveResponsiveArrays(key, cssVariables) {
    let cssValues = cssVariables[key]
    if (!isArray(cssValues) || !cssValues.length) {
      return
    }

    let lastNotNullValue = null

    // responsive cssValues should have 6 eléments, like: “font-size”: [“12px”,  null, “20px“, null, null, null]
    // it's a mobile first representation, so let's support “font-size”: [“12px”,  null, “20px“]
    // which should mean exactly the same as the one above with 6 items
    const numberOfNullLeftovers = breakpointNames.length - cssValues.length
    cssValues = cssValues.concat(Array(numberOfNullLeftovers).fill(null))
    cssValues.forEach((value, index) => {
      const breakpointKey = breakpointNames[index]

      // The theming system is mobile files. So the smallest value (xs) is the default value, the rest
      // values are defined for the larger width (sm, md, lg, xl, xxl).
      // So instead of defining font-size-xs values, let's keep it as font-size as default
      const responsiveKey = breakpointKey !== 'xs' ? `${key}-${breakpointKey}` : key
      if (value) {
        cssVariables[responsiveKey] = value
        lastNotNullValue = value
      } else {
        cssVariables[responsiveKey] = lastNotNullValue
      }
    })
  },

  /**
   * Merge the 3 level of theming (variables, branding and component settings) and flatten the object
   * to match the css variables naming
   *
   * Example theme definition:
   * {
   *   branding: {
   *     logo: {
   *       mobile: 'url',
   *       desktop: 'url'
   *     }
   *   },
   *   variables: {
   *     color: {
   *       primary: '#FFFFFF',
   *       text: {
   *         dark: '#000000',
   *       }
   *     }
   *   }
   * }
   *
   * is transformed to:
   *
   * {
   *   --color-primary: "#FFFFFF",
   *   --color-text-dark: "#000000",
   *   --logo-desktop: "url",
   *   --logo-mobile: "url"
   * }
   *
   * @returns {object} of CSS variables
   */
  themeToCSSConfig(theme) {
    const styles = merge({}, theme.components, theme.branding, theme.variables)
    const cssVariables = flattenObject(styles, '-', '--')

    // TO resolve the references to the theme values
    const resolveCssValueByKey = (key) => {
      cssVariables[key] = this._resolveVariableReference(theme, cssVariables[key])
      cssVariables[key] = this._resolveURLStrings(theme, cssVariables[key])
    }

    const resolveResponsiveArrays = (key) => this._resolveResponsiveArrays(key, cssVariables)

    // At first resolve the responsive array values. It mutates the `cssVariables`!
    Object.keys(cssVariables).forEach(resolveResponsiveArrays)

    // Then resolve the variables. It mutates the `cssVariables`!
    // If a responsive array values refer to a variable, then it gets resolved in this step.
    Object.keys(cssVariables).forEach(resolveCssValueByKey)

    const filteredCssVariables = Object.keys(cssVariables).reduce((filteredVars, key) => {
      if (!isNull(cssVariables[key])) {
        filteredVars[key] = cssVariables[key]
      }
      return filteredVars
    }, {})

    return filteredCssVariables
  },

  // This turns some app-config style setting to CSS variables
  getAppClientStyle() {
    const { bgColor, footerBgColor, bgImg, bgImgMobile } = getConfig(CONFIG.CLIENT_STYLES)
    const config = {
      '--app-bg-color': bgColor
    }

    if (footerBgColor) {
      config['--footer-bg-color'] = footerBgColor
    }

    if (bgImg && bgImgMobile) {
      config['--app-bg-img'] = bgImg
      config['--app-bg-img-mobile'] = bgImgMobile
    }

    return config
  }
}
