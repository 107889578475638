import { AUTH_IDLE_TIMEOUT } from '@/constants/profile.js'
import { mapGetters } from 'vuex'
import { CONFIG } from '@/constants/config.js'

import PopupV3 from '@/components/ui-components/Popup/PopupV3.vue'
import { useLogout } from '@/use/useLogout.js'

export default {
  name: 'AutoLogoutPopup',
  setup() {
    return useLogout()
  },
  components: {
    PopupV3
  },
  created() {
    this.logout()
  },
  computed: {
    ...mapGetters(['getConfig']),
    idleTimeoutMinutes() {
      return this.getConfig(CONFIG.IDLE_TIMEOUT_MINUTES)
    }
  },
  methods: {
    closePopup() {
      this.$store.commit(AUTH_IDLE_TIMEOUT, false)
    }
  }
}
