{
  "en": {
    "footer": {
      "support_title": "Support",
      "your_privacy_choices": "Your Privacy Choices",
      "consent_preferences": "Consent Preferences",
      "aria_label": "Footer navigation"
    }
  },
  "es": {}
}
