const defaultLanguage = 'en'

const fallbackLanguage = 'en'

const supportedLanguages = [
  { abbreviation: 'en', name: 'English' },
  { abbreviation: 'es', name: 'Español' },
  { abbreviation: 'ru', name: 'Pусский' }
]

export { defaultLanguage, fallbackLanguage, supportedLanguages }
