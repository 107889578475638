<template>
  <path
    fill="currentColor"
    d="m19.84 11.73-6.844-6.324A1.457 1.457 0 0 0 11.999 5c-.364 0-.716.144-.996.406L4.159 11.73a.493.493 0 0 0-.109.152.547.547 0 0 0 .054.552l.284.385a.431.431 0 0 0 .301.177.413.413 0 0 0 .325-.115l.763-.705V18c0 .265.095.52.261.707a.844.844 0 0 0 .628.293h3.555a.844.844 0 0 0 .628-.293 1.07 1.07 0 0 0 .261-.707v-3.25h1.778V18c0 .265.095.52.261.707a.844.844 0 0 0 .628.293h3.556c.234 0 .459-.105.625-.29.167-.186.261-.439.263-.702v-5.83l.765.706c.09.085.207.127.325.115a.432.432 0 0 0 .301-.177l.284-.384a.559.559 0 0 0 .053-.554.493.493 0 0 0-.11-.152Zm-2.956 5.77H14.22v-3.25a1.07 1.07 0 0 0-.26-.707.844.844 0 0 0-.629-.294h-2.666a.844.844 0 0 0-.628.294 1.07 1.07 0 0 0-.261.706v3.25H7.11v-6.554L12 6.425l4.888 4.518-.004 6.556Z"
  />
</template>

<script>
export default {
  name: 'IxIconHome'
}
</script>
