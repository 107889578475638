<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12 10.609 18.609 4 20 5.391 13.391 12 20 18.609 18.609 20 12 13.391 5.391 20 4 18.609 10.609 12 4 5.391 5.391 4 12 10.609Z"
    fill="currentColor"
  />
</template>

<script>
export default {
  name: 'IxIconClose'
}
</script>
