// Redirect route collection
// NOT required
// example: { path: '/ixlayer', redirect: () => Trans.getUserSupportedLang() + '/patient/ixlayer' }

import { Trans } from '@/plugins/Translation.js'

export default [
  {
    path: '/activate',
    redirect: { name: 'KitActivateLanding', params: { lang: Trans.defaultLanguage } }
  },
  { path: '/faqs', redirect: { name: 'FAQ', params: { lang: Trans.defaultLanguage } } }
]
