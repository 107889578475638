import { isArray, isNull, isUndefined } from 'lodash-es'
import axios from 'axios'
import { serverError } from '@/classes/Validation.js'
import { transformToCamelCase } from '@/utils/objectUtils.js'

/*
 * Build FormData object from JSON
 * Userful e.g. to upload file with some metadata
 * */
export const buildMultipartForm = (json = {}) => {
  const formData = new FormData()
  for (const [fieldName, field] of Object.entries(json)) {
    if (isNull(field) || isUndefined(field)) continue

    if (isArray(field)) {
      for (let index = 0; index < field.length; index++) {
        const value = field[index]
        formData.append(`${fieldName}[${index}]`, value)
      }
      continue
    }
    formData.append(fieldName, field)
  }
  return formData
}

/*
 Utility to manage POST or PUT internally depending whether the data has `data.id` defined
   * @param resourceRoot - to post the object to
   * @param data - JSON Body of the request
   * @returns {AxiosPromise<any>}
 */
export const save = (resourceRoot, data) => {
  const method = data.id ? axios.put : axios.post
  const url = resourceRoot + (data.id ? data.id + '/' : '')
  return method.bind(axios)(url, data)
}

/*
 Utility to post multipart FormData from a JSON
   * @param form - standard JS object with the form fields to post as multipart form data
   * @param url - to post to
   * @param progressCallback - The progress of the form data upload. Syntax: (percentage) => {...}
   * @returns {AxiosPromise<any>}
 */
export const postMultiPartFormData = (form, url, progressCallback) => {
  const formData = buildMultipartForm(form)

  const onUploadProgress = (event) => {
    if (!progressCallback) return
    const percentage = Math.round((event.loaded * 100) / event.total)
    progressCallback(percentage)
  }

  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  })
}

const backendErrorMapper = {
  '*': (errors) => {
    if (!isArray(errors)) return errors
    return {
      serverError: serverError(errors.join('; '))
    }
  }
}

/*
 Utility to transform snake case nested error messages from the backend to a frontend friendly form

 An example standard 400 error response may look like:
 const error = {
   patient: {
     first_name: [
      'This field may not be blank',
      'Minimum length is 2',
     ]
   }
 }

 const errors = transformToCamelCase(error, backendErrorMapper)

 Let's see what we have in the errors:
 errors = {
   patient: {
     firstName: "This field may not be blank; Minimum length is 2"
   }
 }
 ```
*/
export const transformBackendError = (errorResp) =>
  transformToCamelCase(errorResp, backendErrorMapper)
