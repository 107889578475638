import * as Sentry from '@sentry/vue'

/**
 * @enum {string}
 */
export const SENTRY_LOG_LEVEL = Object.freeze({
  DEBUG: 'debug',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
})

/**
 * Simple Sentry logging. Pass message, and log level.
 * Choose one of {DEBUG, INFO, ERROR, WARNING}
 *
 * @typedef {{
 *  message: string;
 *  level: SENTRY_LOG_LEVEL;
 * }} IxLog
 *
 * @param {IxLog} IxLog
 */
export function logToSentry({ message = '', level = SENTRY_LOG_LEVEL.DEBUG } = {}) {
  Sentry.captureMessage(message, level)
}
