import IXLayerAPI from '@/classes/IXLayerAPI.js'
import common from '@/classes/common.js'
import { errorHandler } from '@/utils/errorUtils.js'

export const FETCH_PRODUCT_LIST = 'PRODUCT_LIST'
export const FETCH_PRODUCT_STATUS_LIST = 'FETCH_PRODUCT_STATUS_LIST'
export const SET_PRODUCT_LIST = 'SET_PRODUCT_LIST'
export const SET_PRODUCT_STATUS_LIST = 'SET_PRODUCT_STATUS_LIST'
export const PRODUCT_STATUS_RESULT_READY_LIST = 'PRODUCT_STATUS_RESULT_READY_LIST'
export const RESET_PRODUCT_STATUS_RESULT_READY_LIST = 'RESET_PRODUCT_STATUS_RESULT_READY_LIST'
export const PRODUCT_STATUS_IN_PROGRESS_LIST = 'PRODUCT_STATUS_IN_PROGRESS_LIST'
export const RESET_PRODUCT_STATUS_IN_PROGRESS_LIST = 'RESET_PRODUCT_STATUS_IN_PROGRESS_LIST'
export const PRODUCT_STATUS_OPEN_ORDER_LIST = 'PRODUCT_STATUS_OPEN_ORDER_LIST'
export const RESET_PRODUCT_STATUS_OPEN_ORDER_LIST = 'RESET_PRODUCT_STATUS_OPEN_ORDER_LIST'
export const KIT_REG_STATUS_UPDATE = 'KIT_REG_STATUS_UPDATE'
export const RESET_KIT_REG_STATUS_UPDATE = 'RESET_KIT_REG_STATUS_UPDATE'
export const KIT_REG_HEADER_OPEN_ORDER = 'KIT_REG_HEADER_OPEN_ORDER'
export const RESET_KIT_REG_HEADER_OPEN_ORDER = 'RESET_KIT_REG_HEADER_OPEN_ORDER'
export const IS_CHECKOUT = 'IS_CHECKOUT'

const getProductStatusList =
  (productStatusKey) =>
  ({ commit }, { queryParams }) => {
    return new Promise((resolve, reject) => {
      IXLayerAPI.getProductStatus(queryParams)
        .then((resp) => {
          commit(productStatusKey, resp)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

const initialProductStatusState = () => ({ productStatusList: null })
const initialProductStatusParamsState = () => ({
  productStatusListParams: { count: 0 }
})
const initialProductStatusResultReadyState = () => ({
  productStatusResultReadyList: null
})
const initialProductStatusResultReadyParamsState = () => ({
  productStatusResultReadyListParams: null
})
const initialProductStatusInProgressState = () => ({
  productStatusInProgressList: null
})
const initialProductStatusInProgressParamsState = () => ({
  productStatusInProgressListParams: null
})
const initialProductStatusOpenOrderState = () => ({
  productStatusOpenOrderList: null
})
const initialKitRegStatusUpdate = () => ({ kitRegStatusUpdate: null })
const initialKitRegHeaderOpenOrder = () => ({ kitRegHeaderOpenOrder: false })

const getDefaultState = () => ({
  ...initialProductStatusState(),
  ...initialProductStatusParamsState(),
  ...initialProductStatusResultReadyState(),
  ...initialProductStatusResultReadyParamsState(),
  ...initialProductStatusInProgressState(),
  ...initialProductStatusInProgressParamsState(),
  ...initialProductStatusOpenOrderState(),
  ...initialKitRegStatusUpdate(),
  ...initialKitRegHeaderOpenOrder(),
  productList: null,
  productListError: null,
  isCheckout: false
})

const state = getDefaultState()

const getters = {
  productList: (state) => state.productList,
  productListError: (state) => state.productListError,
  productStatusList: (state) => state.productStatusList,
  productStatusListParams: (state) => state.productStatusListParams,
  productStatusResultReadyList: (state) => state.productStatusResultReadyList,
  productStatusResultReadyListParams: (state) => state.productStatusResultReadyListParams,
  productStatusInProgressList: (state) => state.productStatusInProgressList,
  productStatusInProgressListParams: (state) => state.productStatusInProgressListParams,
  productStatusOpenOrderList: (state) => state.productStatusOpenOrderList,
  kitRegStatusUpdate: (state) => state.kitRegStatusUpdate,
  kitRegHeaderOpenOrder: (state) => state.kitRegHeaderOpenOrder,
  isCheckout: (state) => state.isCheckout
}

const actions = {
  [FETCH_PRODUCT_LIST]: ({ commit }, organization) => {
    return IXLayerAPI.getProductList({ organization })
      .then((resp) => {
        commit(SET_PRODUCT_LIST, { resp })
        return resp
      })
      .catch((err) => {
        commit(SET_PRODUCT_LIST, { err })
        throw err
      })
  },
  [FETCH_PRODUCT_STATUS_LIST]: getProductStatusList(SET_PRODUCT_STATUS_LIST),
  [PRODUCT_STATUS_RESULT_READY_LIST]: getProductStatusList(PRODUCT_STATUS_RESULT_READY_LIST),
  [PRODUCT_STATUS_IN_PROGRESS_LIST]: getProductStatusList(PRODUCT_STATUS_IN_PROGRESS_LIST),
  [PRODUCT_STATUS_OPEN_ORDER_LIST]: getProductStatusList(PRODUCT_STATUS_OPEN_ORDER_LIST),
  [KIT_REG_STATUS_UPDATE]: ({ commit }, value) => {
    commit('KIT_REG_STATUS_UPDATE', value)
  },
  [KIT_REG_HEADER_OPEN_ORDER]: ({ commit }, value) => {
    commit('KIT_REG_HEADER_OPEN_ORDER', value)
  },
  [IS_CHECKOUT]: ({ commit }, value) => {
    commit('IS_CHECKOUT', value)
  }
}

const mutations = {
  [SET_PRODUCT_LIST]: (state, value) => {
    if (value.err) {
      state.productList = null
      state.productListError = value.err

      const HTTP_STATUS_CODE_FORBIDDEN = 403
      if (value.err?.response?.status !== HTTP_STATUS_CODE_FORBIDDEN) {
        errorHandler({
          sentryErrMessage: `FAILED DUE TO ${value.err.response?.data?.message || value.err}`
        })
      }
      return
    }
    state.productList = value.resp.data.results
  },
  [SET_PRODUCT_STATUS_LIST]: (state, resp) => {
    state.productStatusList = resp.data.results
    state.productStatusListParams = {
      count: resp.data.count
    }
  },
  [PRODUCT_STATUS_RESULT_READY_LIST]: (state, resp) => {
    state.productStatusResultReadyList = resp.data.results
    state.productStatusResultReadyListParams = {
      count: resp.data.count
    }
  },
  [RESET_PRODUCT_STATUS_RESULT_READY_LIST](state) {
    common.resetState(initialProductStatusResultReadyState, state)
    common.resetState(initialProductStatusResultReadyParamsState, state)
  },
  [PRODUCT_STATUS_IN_PROGRESS_LIST]: (state, resp) => {
    state.productStatusInProgressList = resp.data.results
    state.productStatusInProgressListParams = {
      count: resp.data.count
    }
  },
  [RESET_PRODUCT_STATUS_IN_PROGRESS_LIST](state) {
    common.resetState(initialProductStatusInProgressState, state)
    common.resetState(initialProductStatusInProgressParamsState, state)
  },
  [PRODUCT_STATUS_OPEN_ORDER_LIST]: (state, resp) => {
    state.productStatusOpenOrderList = resp.data.results
  },
  [RESET_PRODUCT_STATUS_OPEN_ORDER_LIST](state) {
    common.resetState(initialProductStatusOpenOrderState, state)
  },
  [KIT_REG_STATUS_UPDATE]: (state, resp) => {
    state.kitRegStatusUpdate = resp
  },
  [RESET_KIT_REG_STATUS_UPDATE](state) {
    common.resetState(initialKitRegStatusUpdate, state)
  },
  [KIT_REG_HEADER_OPEN_ORDER]: (state, resp) => {
    state.kitRegHeaderOpenOrder = resp
  },
  [RESET_KIT_REG_HEADER_OPEN_ORDER](state) {
    common.resetState(initialKitRegHeaderOpenOrder, state)
  },
  [IS_CHECKOUT]: (state, resp) => {
    state.isCheckout = resp
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
