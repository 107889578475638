<template>
  <div class="container-fluid">
    <div class="popup row">
      <div class="popup__box col-11 col-md-6 col-lg-4 p-4">
        <div class="mb-4">
          <div v-if="title || $slots.title" class="d-flex justify-content-between mb-1">
            <slot name="title">
              <base-heading as="h2" class="popup__box-title">
                {{ title }}
              </base-heading>
            </slot>

            <icon-base
              v-if="showCloseButton"
              class="popup__close"
              :params="{
                width: 24,
                height: 24,
                viewBox: '0 0 24 24',
                name: 'icon.close'
              }"
              @click="$emit('close')"
            >
              <ix-icon-close />
            </icon-base>
          </div>

          <base-text
            v-if="text1"
            as="p"
            variant="large-regular"
            class="popup__box-text my-2"
            v-html="text1"
          />

          <base-text v-if="text2" as="p" variant="large-regular" class="popup__box-text my-2">
            {{ text2 }}
          </base-text>

          <slot name="body" />
        </div>

        <div v-if="userActionBtns.length > 0" class="popup__buttons">
          <component
            :is="buttonType(btn)"
            v-for="(btn, index) in userActionBtns"
            :key="`btn-${index}`"
            :to="
              btn.routerLinkTo
                ? $i18nRoute({
                    name: btn.routerLinkTo,
                    params: btn.linkToParams
                  })
                : ''
            "
            :secondary="!!btn.secondary"
            :danger="!!btn.danger"
            :is-loading="btn.isLoading"
            :disabled="btn.isLoading || btn.isDisabled"
            class="justify-content-center"
            @click="btn.onClick"
          >
            <b-img v-if="btn.image" :src="btn.image" class="pr-2" height="24" />

            <icon-base v-if="btn.prependIcon" :params="btn.prependIcon.params" class="mr-1">
              <component :is="btn.prependIcon.component" />
            </icon-base>

            <span>{{ $t(btn.title) || $t(btn.linkTitle) }}</span>

            <icon-base v-if="btn.appendIcon" :params="btn.appendIcon.params" class="ml-1 mr-0">
              <component :is="btn.appendIcon.component" />
            </icon-base>
          </component>
        </div>

        <div
          v-if="singleBtn"
          class="d-flex flex-column flex-md-row align-items-stretch justify-content-md-end mx-2 mb-2"
        >
          <base-btn class="justify-content-center w-100" @click="onClick">
            {{ singleBtn }}
          </base-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseBtn from '@/components/ui-components/BaseBtn/BaseBtn.vue'
import GhostBtn from '@/components/ui-components/GhostBtn/GhostBtn.vue'
import IconBase from '@/components/ui-components/IconBase/IconBase.vue'
import IxIconClose from '@/components/icons/IxIconClose.vue'

export default {
  name: 'PopupV3',
  components: {
    GhostBtn,
    IconBase,
    IxIconClose
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    text1: {
      type: String,
      default: ''
    },
    text2: {
      type: String,
      default: ''
    },
    userActionBtns: {
      type: Array,
      default: () => []
    },
    singleBtn: {
      type: String,
      default: ''
    },
    onClick: {
      type: Function,
      default: null
    },
    showCloseButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    buttonType(btn) {
      return btn.isGhost ? GhostBtn : BaseBtn
    }
  }
}
</script>

<style lang="scss">
$popup-z-index: 200;
$popup-bg-color: rgba($color-black, 0.7);
$box-bg-color: $v3-color-white;
$box-text-color: $v3-text-color-dark-pale;

@mixin popup($default-border-radius) {
  border-radius: var(
    theme-var-name('popup', 'default', 'border-radius'),
    responsive-fallback-to-no-variant-var(
      'popup',
      'default',
      'border-radius',
      $default-border-radius
    )
  );
}

.popup {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: $popup-z-index;
  top: 0;
  background-color: $popup-bg-color;

  &__close {
    margin-top: 2px;
    cursor: pointer;
    color: $v3-primary;
  }

  &__box {
    @include popup($v3-main-border-radius);

    background-color: $box-bg-color;
    position: absolute;
    height: auto;
    margin: auto;
  }

  &__box-title {
    color: $v3-text-color-dark;
  }

  &__box-text {
    color: $box-text-color;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 4px;

    @include media-breakpoint-up(md) {
      flex-direction: row-reverse;
    }
  }
}
</style>
