import sanitizeHtml from 'sanitize-html'
import IXLayerAPI from '@/classes/IXLayerAPI.js'
import * as Sentry from '@sentry/vue'
import common from '@/classes/common.js'
import storage from '@/classes/Storage.js'
import { getOmittedFields, profileFields } from '@/utils/omitUtils.js'
import { setGA4UserId } from '@/utils/analytics/setup.js'
import { hasAcceptedMandatoryConsents } from '@/utils/consentUtils.js'
import {
  AUTH_ERROR,
  AUTH_IDLE_TIMEOUT,
  AUTH_LOGOUT,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_TOKEN_NOT_FOUND_OR_INVALID,
  HAS_PASSWORD_TOKEN,
  IS_AUTHENTICATED,
  LOGOUT_SILENTLY,
  ORG_BY_ACCESS_CODE,
  PROFILE_REQUEST,
  PROFILE_RES_CONSENT_STATUS,
  PROFILE_STATUS,
  RESET_ORG_BY_ACCESS_CODE,
  SET_PROFILE_REQUEST_PROMISE
} from '@/constants/profile.js'

const initialOrgByAccessCode = () => ({ orgByAccessCode: null })
const initialAuthStatus = () => ({
  isAuthenticated: Boolean(storage.getStoredItem(IS_AUTHENTICATED)) || false,
  status: null,
  profile: null
})

const state = {
  ...initialAuthStatus(),
  profileStatus: null,
  defaultSignedInRouterLinkTo: 'TestDashboard',
  researchConsentStatus: null,
  idleTimeout: false,
  hasPasswordToken: {
    existence: false
  },
  profileRequestPromise: null,
  ...initialOrgByAccessCode()
}

const sanitize = (content) => sanitizeHtml(content, { allowedTags: [], allowedAttributes: {} })
const capitalize = (word) => (word ? word.charAt(0).toUpperCase() + word.slice(1) : '')

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  authStatus: (state) => state.status,
  hasInvalidToken: (state) => state.status === 'tokenInvalid',
  profile: (state) => state.profile,
  userId: (state) => state.profile?.id || null,
  userFullName: (state, getter) => sanitize(`${getter.userFirstName} ${getter.userLastName}`),
  userFirstName: (state) => sanitize(capitalize(state?.profile?.first_name)) || '',
  userLastName: (state) => sanitize(capitalize(state?.profile?.last_name)) || '',
  organization: (state) => state?.profile?.organization || null,
  organizationName: (state) => state?.profile?.organization?.business_name || '',
  defaultSignedInRouterLinkTo: (state) => state.defaultSignedInRouterLinkTo,
  profileStatus: (state) => state.profileStatus,
  researchConsentStatus: (state) => state.researchConsentStatus,
  idleTimeout: (state) => state.idleTimeout,
  hasPasswordToken: (state) => state.hasPasswordToken,
  orgByAccessCode: (state) => state.orgByAccessCode,
  insurances: (state) => state.profile && state.profile.insurance_info,
  isProfileComplete: (state) => common.hasProfileCompleted(state?.profile),
  hasAcceptedMandatoryConsents: (state) => hasAcceptedMandatoryConsents(state.profile)
}

const actions = {
  [AUTH_REQUEST]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST)
      IXLayerAPI.login(data)
        .then((resp) => {
          commit(AUTH_SUCCESS)
          resolve(resp)
        })
        .catch((err) => {
          commit(AUTH_ERROR, err)
          reject(err)
        })
    })
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      IXLayerAPI.logout()
        .then((resp) => {
          commit(AUTH_LOGOUT)
          resolve(resp)
        })
        .catch((err) => {
          commit(AUTH_ERROR)
          reject(err)
        })
    })
  },
  [AUTH_TOKEN_NOT_FOUND_OR_INVALID]: ({ commit }) => {
    commit(AUTH_TOKEN_NOT_FOUND_OR_INVALID)
    return Promise.resolve()
  },
  [LOGOUT_SILENTLY]: ({ commit }) => {
    commit(LOGOUT_SILENTLY)
    return Promise.resolve()
  },
  [HAS_PASSWORD_TOKEN]: ({ commit }) => {
    return new Promise((resolve) => {
      commit(HAS_PASSWORD_TOKEN)
      resolve()
    })
  },
  [PROFILE_REQUEST]: ({ commit, state, getters }) => {
    // Lightweight promise cache to make sure the request is not sent multiple times
    // while running
    if (state.profileRequestPromise) {
      return state.profileRequestPromise
    }
    const promise = new Promise((resolve, reject) => {
      IXLayerAPI.getMyProfile({
        omit: getOmittedFields(profileFields, getters)
      })
        .then((resp) => {
          commit(PROFILE_REQUEST, resp)
          commit(PROFILE_RES_CONSENT_STATUS, resp)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          commit(SET_PROFILE_REQUEST_PROMISE, null)
        })
    })
    commit(SET_PROFILE_REQUEST_PROMISE, promise)
    return promise
  },
  [PROFILE_STATUS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      IXLayerAPI.getMyStatus()
        .then((resp) => {
          commit(PROFILE_STATUS, resp)
          commit(AUTH_SUCCESS)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [HAS_PASSWORD_TOKEN]: (state, value) => {
    state.hasPasswordToken = value
  },
  [AUTH_SUCCESS]: (state) => {
    state.status = 'success'
    state.isAuthenticated = true
    storage.setStorageItem(IS_AUTHENTICATED, true)
  },
  [AUTH_ERROR]: (state) => {
    storage.clearStoredItem(IS_AUTHENTICATED)
    state.isAuthenticated = false
    state.status = 'error'
  },
  [AUTH_TOKEN_NOT_FOUND_OR_INVALID]: (state) => {
    storage.clearStoredItem(IS_AUTHENTICATED)
    state.isAuthenticated = false
    state.status = 'tokenInvalid'
  },
  [LOGOUT_SILENTLY]: (state) => {
    storage.clearStoredItem(IS_AUTHENTICATED)
    state.isAuthenticated = false
    state.status = null
  },
  [AUTH_LOGOUT]: (state) => {
    Sentry.getCurrentScope().setUser(null)
    setGA4UserId('')
    storage.clearStoredItem(IS_AUTHENTICATED)
    storage.clearStoredItem('isProfileComplete')
    common.resetState(initialAuthStatus, state)
  },
  [PROFILE_REQUEST]: (state, resp) => {
    state.profile = resp.data
    Sentry.getCurrentScope().setUser({ id: state.profile.id })
    setGA4UserId(state.profile.id)
    const isProfileComplete = common.hasProfileCompleted(state.profile)
    storage.setStorageItem('isProfileComplete', isProfileComplete)
  },
  [PROFILE_RES_CONSENT_STATUS]: (state, resp) => {
    state.researchConsentStatus = resp.data.research_consent_status
  },
  [PROFILE_STATUS]: (state, resp) => {
    const res = resp.data
    state.profileStatus = {
      hasOnboardedProductstatus: res.has_onboarded_productstatus,
      hasProductStatus: res.has_productstatus,
      hasCancelledProductStatus: res.has_cancelled_productstatus,
      hasRegisteredKit: res.has_registered_kit,
      hasPurchase: res.has_purchase,
      inProgressCount: res.in_progress_count,
      kitErrorCount: res.kit_error_count,
      resultsReadyCount: res.results_ready_count
    }
  },
  [AUTH_IDLE_TIMEOUT]: (state, show) => {
    state.idleTimeout = show
  },
  [ORG_BY_ACCESS_CODE]: (state, resp) => {
    state.orgByAccessCode = resp
  },
  [RESET_ORG_BY_ACCESS_CODE](state) {
    common.resetState(initialOrgByAccessCode, state)
  },
  [SET_PROFILE_REQUEST_PROMISE]: (state, promise) => {
    state.profileRequestPromise = promise
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
