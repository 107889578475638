import IXLayerAPI from '@/classes/IXLayerAPI.js'

export const HAS_CONNECTION_ERROR = 'HAS_CONNECTION_ERROR'
export const SET_INVALID_REFERRER = 'SET_INVALID_REFERRER'
export const CHECK_CONNECTION = 'CHECK_CONNECTION'

const state = {
  hasConnectionError: false,
  isInvalidReferrer: false
}

const getters = {
  hasConnectionError: (state) => state.hasConnectionError,
  isInvalidReferrer: (state) => state.isInvalidReferrer
}

const actions = {
  [SET_INVALID_REFERRER]: ({ commit }, value) => {
    commit('SET_INVALID_REFERRER', value)
  },
  [HAS_CONNECTION_ERROR]: ({ commit }, value) => {
    commit('HAS_CONNECTION_ERROR', value)
  },
  [CHECK_CONNECTION]: async ({ commit }) => {
    try {
      await IXLayerAPI.pingConfig()
      commit('HAS_CONNECTION_ERROR', false)
    } catch (e) {
      commit('HAS_CONNECTION_ERROR', true)
    }
  }
}

const mutations = {
  [HAS_CONNECTION_ERROR]: (state, value) => {
    state.hasConnectionError = value
  },

  [SET_INVALID_REFERRER]: (state, value) => {
    state.isInvalidReferrer = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
