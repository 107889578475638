export const KIT_ID_VALIDATION = Object.freeze({
  INVALID_KIT_ID_ERROR: 'invalid',
  UNABLE_TO_ACTIVATE_KIT_ERROR: 'unableToActivateKit'
})

export const KIT_ACTIVATION = Object.freeze({
  KIT_ID: 'kit_activation.kit_id',
  PRODUCT_ID: 'kit_activation.product_id'
})

export const KIT_ACTIVATION_RESULTS = Object.freeze({
  ALREADY_REGISTERED: 'already_registered',
  CAN_BE_REGISTERED: 'can_be_registered',
  INVALID_PRODUCT_ID: 'invalid_product_id',
  INVALID_KIT_ID: 'invalid_kit_id'
})
