import IXLayerAPI from '@/classes/IXLayerAPI.js'
import { sortBy } from 'lodash-es'
import { i18n } from '@/plugins/i18n.js'
import { errorHandler } from '@/utils/errorUtils.js'

export const INSURANCE_COMPANY_LIST = 'INSURANCE_COMPANY_LIST'

const state = {
  insuranceCompanyList: []
}

const getters = {
  insuranceCompanyList: (state) => state.insuranceCompanyList
}

const actions = {
  [INSURANCE_COMPANY_LIST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      IXLayerAPI.getInsuranceCompany()
        .then((insurances) => {
          commit(INSURANCE_COMPANY_LIST, { insurances })
          resolve(insurances)
        })
        .catch((err) => {
          commit(INSURANCE_COMPANY_LIST, { err })
          reject(err)
        })
    })
  }
}

const mutations = {
  [INSURANCE_COMPANY_LIST]: (state, value) => {
    if (value.err) {
      state.insuranceCompanyList = null
      state.insuranceCompanyListError = value.err
      errorHandler({
        toastedMessage: i18n.t('error.service_not_available_insurance'),
        sentryErrMessage: `FAILED DUE TO ${value.err.response?.data?.message || value.err}`
      })
      return
    }

    // TODO: would be better to sort by the backend to return the ID of the object
    state.insuranceCompanyList = sortBy(value.insurances || [], 'name').map((item, index) => {
      item.index = index
      return item
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
