import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { castToVueI18n, createI18n } from 'vue-i18n-bridge'
import en from '@/locales/en.json'
import es from '@/locales/es.json'
import datetimeFormats from '@/locales/datetimeFormats.json'

// https://vue-i18n.intlify.dev/guide/migration/vue2.html
Vue.use(VueI18n, { bridge: true })

export const i18n = castToVueI18n(
  createI18n(
    {
      legacy: true,
      locale: import.meta.env.VUE_APP_I18N_LOCALE || 'en',
      datetimeFormats,
      missingWarn: false,
      warnHtmlMessage: import.meta.env.DEV,
      fallbackLocale: import.meta.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
      messages: {
        en,
        es
      }
    },
    VueI18n
  )
)

Vue.use(i18n)
