import IXLayerAPI from '@/classes/IXLayerAPI.js'
import {
  INTEGRATION_SETTING_CMS,
  INTEGRATION_SETTING_ZOCDOC
} from '@/constants/integrationSetting.js'
import axios from 'axios'

export const FETCH_INTEGRATION_SETTINGS = 'FETCH_INTEGRATION_SETTINGS'
const SET_INTEGRATION_SETTINGS = 'SET_INTEGRATION_SETTINGS'
const SET_INTEGRATION_SETTINGS_REQUEST_FAILED = 'SET_INTEGRATION_SETTINGS_REQUEST_FAILED'
export const GET_ZOCDOC_ACCESS_TOKEN = 'GET_ZOCDOC_ACCESS_TOKEN'
const SET_ZOCDOC_ACCESS_TOKEN = 'SET_ZOCDOC_ACCESS_TOKEN'

const state = {
  integrationSettings: {},
  integrationSettingsRequestFailed: false,
  zocdocAccessToken: ''
}

const getters = {
  integrationSettings: (state) => state.integrationSettings,
  integrationSettingsRequestFailed: (state) => state.integrationSettingsRequestFailed,
  getIntegrationSetting: (state) => (setting) => state.integrationSettings?.[setting],
  cmsApiInstance: (state) => {
    let baseURL = import.meta.env.VUE_APP_CMS_ROOT_API

    const cmsSettings = state.integrationSettings[INTEGRATION_SETTING_CMS]
    if (cmsSettings && cmsSettings.CMS_SERVER_SCHEMA && cmsSettings.CMS_SERVER_HOST) {
      // TODO: Extract /api/v2 to a CMS_SERVER_PATH setting
      baseURL = `${cmsSettings.CMS_SERVER_SCHEMA}://${cmsSettings.CMS_SERVER_HOST}/api/v2`
    }

    const api = axios.create({ baseURL })
    delete api.defaults.withCredentials
    delete api.defaults.headers.common

    return api
  },
  zocdocApiInstance: (state) => (accessToken) => {
    let baseURL = import.meta.env.VUE_APP_ZOCDOC_API_ROOT

    const zocdocSettings = state.integrationSettings[INTEGRATION_SETTING_ZOCDOC]
    if (zocdocSettings && zocdocSettings.API_ROOT) {
      baseURL = zocdocSettings.API_ROOT
    }

    const options = {
      baseURL
    }

    if (accessToken) {
      options.headers = {
        Authorization: `Bearer ${accessToken}`
      }
    }

    const api = axios.create(options)
    delete api.defaults.withCredentials
    delete api.defaults.headers.common

    return api
  },
  zocdocAccessToken: (state) => state.zocdocAccessToken,
  zocdocReferenceData: (state) => state.zocdocReferenceData
}

const actions = {
  [FETCH_INTEGRATION_SETTINGS]: async ({ commit }) => {
    const response = await IXLayerAPI.getIntegrationSettings()

    try {
      commit(SET_INTEGRATION_SETTINGS, response.data)
      commit(SET_INTEGRATION_SETTINGS_REQUEST_FAILED, false)
    } catch (err) {
      commit(SET_INTEGRATION_SETTINGS, { err })
      commit(SET_INTEGRATION_SETTINGS_REQUEST_FAILED, false)
      throw err
    }
  },

  [GET_ZOCDOC_ACCESS_TOKEN]: async ({ state, commit }) => {
    if (state.zocdocAccessToken) {
      return
    }

    try {
      const response = await IXLayerAPI.getZocdocAccessToken()
      const { access_token } = response.data

      commit(SET_ZOCDOC_ACCESS_TOKEN, access_token)
    } catch (err) {
      commit(SET_ZOCDOC_ACCESS_TOKEN, null)
      throw err
    }
  }
}

const mutations = {
  [SET_INTEGRATION_SETTINGS]: (state, data) => {
    state.integrationSettings = data
  },

  [SET_INTEGRATION_SETTINGS_REQUEST_FAILED]: (state, value) => {
    state.integrationSettingsRequestFailed = value
  },

  [SET_ZOCDOC_ACCESS_TOKEN]: (state, value) => {
    state.zocdocAccessToken = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
