import { i18n } from '@/plugins/i18n.js'
import { trackEvent } from '@/utils/analytics/events.js'

// https://support.google.com/analytics/answer/1033863
const UTM_PREFIX = 'utm_'

export const trackPageView = (route, parameters = {}) => {
  if (!window.gtag) return

  const localizedPathWithoutParams = route?.matched[route?.matched?.length - 1]?.path
    // :lang should be kept, any other parameters should be removed, because they can contain PII
    .replace(/\/(?!:lang):.+$/, '')
    .replace(':lang', i18n.locale)

  if (!localizedPathWithoutParams) return

  let url = new URL(`${window.location.origin}${localizedPathWithoutParams}`)

  let filteredQueryParams = []
  const queryParamKeys = Object.keys(route.query)
  if (
    queryParamKeys.length > 0 &&
    (queryParamKeys.some((key) => key.startsWith(UTM_PREFIX)) ||
      route.meta?.queryParamsToKeep?.length > 0)
  ) {
    filteredQueryParams = Object.entries(route.query).filter(
      ([key]) => key.startsWith(UTM_PREFIX) || route.meta.queryParamsToKeep.includes(key)
    )
  }

  if (filteredQueryParams.length > 0) {
    const query = new URLSearchParams(filteredQueryParams).toString()
    url = new URL(`${window.location.origin}${localizedPathWithoutParams}?${query}`)
  }

  const eventParameters = {
    page_location: url.toString(),
    page_path: url.pathname,
    page_title: route.meta?.title,
    event_callback: () => {
      if (typeof window.pageViewEventCallback === 'function') {
        window.pageViewEventCallback()
      }
    },
    ...parameters
  }

  if (route.meta?.sendTo) {
    eventParameters.send_to = route.meta?.sendTo
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/views?client_type=gtag
  trackEvent('page_view', eventParameters)
}
