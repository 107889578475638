import {
  IGNORED_WORKFLOW_STATES,
  WORKFLOW_STATE_APPOINTMENT_SCHEDULED,
  WORKFLOW_STATE_KIT_ERROR,
  WORKFLOW_STATE_KIT_REGISTERED,
  WORKFLOW_STATE_LDT_RESULTS_SENT,
  WORKFLOW_STATE_RESULT_APPROVED,
  WORKFLOW_STATE_TEST_CONSENT_SIGNED
} from '@/constants/workflowStates.js'
import { isStateInTransitionHistory } from '@/utils/workflowUtils.js'
import { RESULT_APPROVED_STATES } from '@/constants/results.js'

export const getWorkflowState = (productStatus, useStateWorkflowProperty = false) => {
  const shouldShowPreviousState =
    (productStatus.state === WORKFLOW_STATE_KIT_ERROR && productStatus.is_recoverable) ||
    IGNORED_WORKFLOW_STATES.includes(productStatus.state)

  if (shouldShowPreviousState) {
    return productStatus.transition_history?.[productStatus.transition_history.length - 1]
      ?.from_state
  }

  // Currently we don't have a state when the kit id is registered but the user has not consented to the test.
  // This is a workaround to override the status title.
  // TODO: The standalone consent page in Kit activation flow will be deprecated soon, then we won't need
  // this anymore.
  const hasConsented = isStateInTransitionHistory(productStatus, WORKFLOW_STATE_TEST_CONSENT_SIGNED)

  if (productStatus.state === WORKFLOW_STATE_KIT_REGISTERED && !hasConsented) {
    return 'kit_registered_without_consent'
  }

  if (productStatus.state === WORKFLOW_STATE_APPOINTMENT_SCHEDULED) {
    const lastNonScheduledState = productStatus.transition_history?.findLast(
      (state) => state.to_state !== WORKFLOW_STATE_APPOINTMENT_SCHEDULED
    )
    if (
      RESULT_APPROVED_STATES.includes(lastNonScheduledState.to_state) &&
      lastNonScheduledState.from_state !== WORKFLOW_STATE_APPOINTMENT_SCHEDULED
    ) {
      return lastNonScheduledState.to_state
    }
  }

  if (useStateWorkflowProperty || productStatus.current_workflow_stage !== null) {
    return productStatus.state
  }

  // To be revisited on Workflows v2 migration: this is an exception where the LDT order status
  // is still pending, but the product status has already progressed to approved state once.
  // In this case the results should not be available to the patient yet.
  const isStillAwaitingApproval =
    productStatus.most_advanced_status === WORKFLOW_STATE_RESULT_APPROVED &&
    productStatus.state === WORKFLOW_STATE_LDT_RESULTS_SENT

  return productStatus.state === WORKFLOW_STATE_KIT_ERROR || isStillAwaitingApproval
    ? productStatus.state
    : productStatus.most_advanced_status
}
