import Vue from 'vue'
import { logToSentry, SENTRY_LOG_LEVEL } from '@/utils/logging.js'

export const errorHandler = ({
  toastedMessage = null,
  sentryErrMessage = null,
  shouldSentryThrowError = true
}) => {
  if (toastedMessage) {
    Vue.toasted.show(toastedMessage)
  }
  if (sentryErrMessage) {
    logToSentry({
      message: sentryErrMessage,
      level: SENTRY_LOG_LEVEL.ERROR
    })
    if (shouldSentryThrowError) {
      throw new Error(sentryErrMessage)
    }
  }
}
