import storage from '@/classes/Storage.js'

const CHECK_INTERVAL = 1000
const STORE_KEY = 'lastAction'

export default class AutoLogoutService {
  get lastAction() {
    return parseInt(storage.getStoredItem(STORE_KEY))
  }

  set lastAction(value) {
    storage.setStorageItem(STORE_KEY, value)
  }

  constructor(store, minutes, cb) {
    this.auth = store
    this.minutes = minutes
    this.callback = cb
    this.interval = null
    this.initListener()
    this.updateLastAction()
  }

  initListener() {
    document.body.addEventListener('click', () => this.updateLastAction())
    document.body.addEventListener('mouseover', () => this.updateLastAction())
    document.body.addEventListener('mouseout', () => this.updateLastAction())
    document.body.addEventListener('keydown', () => this.updateLastAction())
    document.body.addEventListener('keyup', () => this.updateLastAction())
    document.body.addEventListener('keypress', () => this.updateLastAction())
  }

  updateLastAction() {
    this.lastAction = Date.now()
  }

  initInterval() {
    this.interval = setInterval(() => {
      this.check()
    }, CHECK_INTERVAL)
  }

  stopInterval() {
    clearInterval(this.interval)
  }

  check() {
    // if not authenticated then no need to check idle time
    if (!this.auth.getters.isAuthenticated) return

    const timeleft = this.lastAction + this.minutes * 60 * 1000
    const diff = timeleft - Date.now()
    if (diff < 0) {
      this.callback()
    }
  }
}
