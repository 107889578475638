import { useRouter } from 'vue-router/composables'
import useTranslation from '@/use/useTranslation.js'
import useBackButtonConfirmationPopup from '@/use/useBackButtonConfirmationPopup.js'
import IXLayerAPI from '@/classes/IXLayerAPI.js'
import { FETCH_PRODUCT_LIST } from '@/store/products.js'
import { store } from '@/store/index.js'
import { getConfig } from '@/use/useConfig.js'
import { CONFIG } from '@/constants/config.js'
import { filterNoAtHomeOrderableProducts } from '@/utils/productUtils.js'

export function useOrderBlockBackRoute(blockedRouteNames) {
  const router = useRouter()
  const $t = useTranslation()

  return useBackButtonConfirmationPopup({
    blockedRouteNames,
    text: $t('popup.order_back_confirm_popup.text'),
    title: $t('popup.order_back_confirm_popup.title'),
    buttons: {
      goAwaySecondary: {
        title: $t('popup.order_back_confirm_popup.secondary_button'),
        onClick: () => {
          router.push({ name: 'TestDashboard' }).catch(() => {})
        }
      },
      stayHerePrimary: {
        title: $t('popup.order_back_confirm_popup.primary_button')
      }
    }
  })
}

export async function useAccessCodeRouting(accessCode) {
  try {
    const res = await IXLayerAPI.getOrganizationByAccessCode(accessCode)

    const organization = res?.data
    const orgId = organization?.id

    if (getConfig(CONFIG.ORGANIZATION) !== organization?.id) {
      await store.dispatch(FETCH_PRODUCT_LIST, organization?.id)
    }
    const productList = store.getters.productList

    const availableProducts = filterNoAtHomeOrderableProducts(productList, organization)

    let location = {
      name: 'PatientKitSelection',
      params: { accessCode, orgId }
    }

    if (availableProducts.length === 1) {
      const productId = availableProducts[0].id

      location = {
        name: 'OrderTestQuestionnaire',
        params: { accessCode, orgId, productId }
      }
    }

    return {
      success: true,
      availableProducts,
      location,
      organization
    }
  } catch (err) {
    return {
      success: false,
      error: err.response?.data?.message || err
    }
  }
}
