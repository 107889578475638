import { getConfig } from '@/use/useConfig.js'
import { CONFIG } from '@/constants/config.js'

/**
 * Simple function to handle session vs localStorage auth token fetch
 * @returns {string}
 */
const getStoredItem = (name) => {
  let value = sessionStorage.getItem(name)
  value = !!value && value !== 'undefined' ? value : localStorage.getItem(name)
  return value
}

/**
 * Simple func to handle setting token based on config preferences
 * @param name
 * @param value
 */
const setStorageItem = (name, value) => {
  if (getConfig(CONFIG.USE_SESSION_STORAGE)) {
    return sessionStorage.setItem(name, value)
  } else {
    return localStorage.setItem(name, value)
  }
}

const clearStorage = () => {
  if (getConfig(CONFIG.USE_SESSION_STORAGE)) {
    sessionStorage.clear()
  } else {
    localStorage.clear()
  }
}

const clearStoredItem = (name) => {
  sessionStorage.removeItem(name)
  localStorage.removeItem(name)
}

export default {
  getStoredItem,
  setStorageItem,
  clearStorage,
  clearStoredItem
}
