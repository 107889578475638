import IXLayerAPI from '@/classes/IXLayerAPI.js'

export const GET_HEALTH_CARE_PROVIDERS = 'GET_HEALTH_CARE_PROVIDERS'
export const CREATE_HEALTH_CARE_PROVIDER = 'CREATE_HEALTH_CARE_PROVIDER'

const state = {
  providersCount: 0,
  providers: []
}

const getters = {
  providers: (state) => state.providers
}

const actions = {
  [GET_HEALTH_CARE_PROVIDERS]: async ({ commit }) => {
    try {
      const response = await IXLayerAPI.getHealthCareProviders()
      commit(GET_HEALTH_CARE_PROVIDERS, response.data)

      return response.data
    } catch (err) {
      throw new Error(err)
    }
  },

  [CREATE_HEALTH_CARE_PROVIDER]: async (context, data) => {
    try {
      const response = await IXLayerAPI.createHealthCareProvider(data)
      return response.data
    } catch (err) {
      throw new Error(err)
    }
  }
}

const mutations = {
  [GET_HEALTH_CARE_PROVIDERS]: (state, data) => {
    state.providersCount = data.count
    state.providers = data.results
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
