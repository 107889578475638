export const PAGE_LIMIT = 20
export const DEFAULT_ORG_CONTENT_ID = '00000000-0000-0000-0000-000000000000'

export const CONTENT_TYPES = Object.freeze({
  BIOMARKER_EDUCATION: 'BIOMARKER_EDUCATION',
  BIOMARKER_SUGGESTION: 'BIOMARKER_SUGGESTION',
  PANEL_DESCRIPTION: 'PANEL_DESCRIPTION',
  RESULT_EDUCATION: 'RESULT_EDUCATION',
  RESULT_SUMMARY_DESCRIPTION: 'RESULT_SUMMARY_DESCRIPTION',
  WELCOME_PAGE: 'WELCOME_PAGE'
})

export const EDUCATION_SECTION_CONTENT_TYPES = [
  CONTENT_TYPES.BIOMARKER_EDUCATION,
  CONTENT_TYPES.BIOMARKER_SUGGESTION
]
