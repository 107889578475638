import { store } from '@/store/index.js'
import { Trans } from '@/plugins/Translation.js'
import { getFallbackPath } from '@/utils/routeUtils.js'
import { i18n } from '@/plugins/i18n.js'

export default [
  {
    path: 'select-test',
    name: 'SelectTest',
    component: () => import('@/components/views/SelectTest/SelectTest.vue'),
    beforeEnter: async (to, from, next) => {
      if (store.getters.featureFlags.enableSelectATest) {
        return next()
      }

      return next(`${Trans.defaultLanguage}/${getFallbackPath()}`)
    },
    meta: {
      title: i18n.t('headful_title.select_a_test'),
      requireProfileWhenAuthenticated: true
    }
  },
  {
    path: 'select-test/eligibility/:accessCode/:orgId',
    name: 'SelectTestEligibility',
    component: () => import('@/components/views/SelectTest/SelectTestEligibility.vue'),
    props: true,
    meta: {
      title: i18n.t('headful_title.select_a_test'),
      requireProfileWhenAuthenticated: true,
      isFooterHidden: true
    }
  }
]
