import IXLayerAPI from '@/classes/IXLayerAPI.js'
import { KIT_ACTIVATION } from '@/constants/kitActivation.js'

export const GET_FEATURE_FLAGS = 'GET_FEATURE_FLAGS'
const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS'
const SET_FEATURE_PROMISE = 'SET_FEATURE_PROMISE'
const SET_FEATURE_FLAGS_REQUEST_FAILED = 'SET_FEATURE_FLAGS_REQUEST_FAILED'

const state = {
  featureFlags: {},
  featurePromise: null,
  featureFlagsRequestFailed: false
}

const getters = {
  featureFlags: (state) => state.featureFlags,
  featureFlagsRequestFailed: (state) => state.featureFlagsRequestFailed,
  hasUserRegistration: (state) => state.featureFlags?.allowUserReg,
  isAutomaticKitActivation: (state) =>
    state.featureFlags?.kitIdAutoSelectsProduct && !!sessionStorage.getItem(KIT_ACTIVATION.KIT_ID),
  useWorkflowStatusesEnabled: (state) => state.featureFlags?.useWorkflowStatuses,
  isSchedulingFeatureEnabled: (state) => state.featureFlags?.patientManagedScheduleAppointments,
  isEfaxAvailable: (state) => state.featureFlags?.enableManualEfax,
  isEmailResultAvailable: (state) => state.featureFlags?.enableEmailResult
}

const actions = {
  [GET_FEATURE_FLAGS]: async ({ commit }) => {
    const loadFeatures = async () => {
      try {
        const response = await IXLayerAPI.getFeatureFlags()
        commit(SET_FEATURE_FLAGS, response.data)
        commit(SET_FEATURE_FLAGS_REQUEST_FAILED, false)
      } catch (error) {
        commit(SET_FEATURE_FLAGS_REQUEST_FAILED, true)
        throw new Error(error)
      } finally {
        commit(SET_FEATURE_PROMISE, null)
      }
    }

    // Promise cache to make sure the request is not sent multiple times while running
    if (state.featurePromise) {
      return state.featurePromise
    }
    const promise = loadFeatures()
    commit(SET_FEATURE_PROMISE, promise)
    return promise
  }
}

const mutations = {
  [SET_FEATURE_FLAGS]: (state, data) => {
    state.featureFlags = data
  },
  [SET_FEATURE_PROMISE]: (state, promise) => {
    state.featurePromise = promise
  },
  [SET_FEATURE_FLAGS_REQUEST_FAILED]: (state, value) => {
    state.featureFlagsRequestFailed = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
