<i18n src="./locales/lang.json"></i18n>

<template>
  <div class="site-unavailable-error">
    <h1 class="site-unavailable-error__title">{{ $t('site_unavailable.title') }}</h1>
    <p class="site-unavailable-error__description">{{ $t('site_unavailable.description') }}</p>
  </div>
</template>

<script>
export default {
  name: 'SiteUnavailableError'
}
</script>

<style scoped lang="scss">
.site-unavailable-error {
  padding: 16px;
  flex: 1;
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f6f7;
  color: $v3-color-dark-shark-gray;
  text-align: center;

  &__title {
    font-size: 22px;
    line-height: 1.45;
    font-weight: 600;
  }

  &__description {
    font-size: 16px;
    line-height: 1.375;
  }
}
</style>
